import { OtpContactType } from "@technis/shared";
import {
  Checkbox_V2,
  DefaultDropdownInput,
  FontSize,
  FontWeight,
  IconCommon,
  IconName,
  type PhoneChangeEventArgs,
  SimpleDropdown,
  Size,
  TextV2,
  useTheme,
} from "@technis/ui";
import React, { type FC, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { countriesPhones } from "@common/configs";
import { useDesktopResolution } from "@common/hooks/useDesktopResolution";
import { type CountryPhoneData } from "@common/interfaces";

import { FormItem } from "@components/common";
import { OnBoardingPhoneInput } from "@components/onboarding";
import { OnboardingContext } from "@components/onboarding/Onboarding/Onboarding";

import { translation } from "@lang/translation";

import styles from "./security.module.scss";

export const Security: FC = () => {
  const { t } = useTranslation();
  const caption = {
    extraFactorTitle: t(translation.onboarding.steps.security.extraFactorTitle),
    extraFactorSubTitle: t(translation.onboarding.steps.security.extraFactorSubTitle),
    activateExtraFactorLabel: t(translation.onboarding.steps.security.activateExtraFactorLabel),
    activateExtraFactorSubLabel: t(translation.onboarding.steps.security.activateExtraFactorSubLabel),
  };

  const { updateFormFilledState, updateData, email, twoFactorVerificationMethod, phoneNumber, countryISO, countryCode } =
    useContext(OnboardingContext);
  const isTwoFactorChecked = Boolean(twoFactorVerificationMethod);

  const selectedVerificationMethod = useMemo(() => {
    if (twoFactorVerificationMethod) {
      return twoFactorVerificationMethod;
    }

    if (email) {
      return OtpContactType.EMAIL;
    }

    return OtpContactType.PHONE;
  }, [email, twoFactorVerificationMethod]);

  const isEmailSelected = selectedVerificationMethod === OtpContactType.EMAIL;
  const isPhoneSelected = selectedVerificationMethod === OtpContactType.PHONE;

  const themeColor = useTheme().theme.colors;

  const { isDesktopResolution } = useDesktopResolution();
  const inputSize = isDesktopResolution ? Size.LARGE : Size.MEDIUM;

  const onTwoFactorChange = (isChecked: boolean): void => {
    if (isChecked) {
      updateData({
        propName: "twoFactorVerificationMethod",
        value: selectedVerificationMethod,
      });
      return;
    }

    updateData({
      propName: "twoFactorVerificationMethod",
      value: undefined,
    });
  };

  const handleTwoFactorAuthClick = (optionName: OtpContactType) => () => {
    updateData({
      propName: "twoFactorVerificationMethod",
      value: optionName,
    });
  };

  const handleOnCountryChange = ({ countryISO }: CountryPhoneData): void => {
    updateData({
      propName: "countryISO",
      value: countryISO,
    });
  };

  const handlePhoneChange = ({ inputValue, isCompleted }: PhoneChangeEventArgs): void => {
    updateFormFilledState(isCompleted);
    updateData({
      propName: "phoneNumber",
      value: inputValue,
    });
  };

  useEffect(() => {
    if (isPhoneSelected) {
      return;
    }

    updateFormFilledState(isEmailSelected || !isTwoFactorChecked);
  }, [isEmailSelected, isPhoneSelected, isTwoFactorChecked, updateFormFilledState]);

  return (
    <FormItem className={styles.wrapper} subTitle={caption.extraFactorSubTitle} title={caption.extraFactorTitle}>
      <div className={styles.extraFactorCheck}>
        <Checkbox_V2
          label={caption.activateExtraFactorLabel}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          checked={isTwoFactorChecked}
          checkboxSize={Size.MEDIUM}
          onChange={onTwoFactorChange}
        />
      </div>

      {isTwoFactorChecked && (
        <>
          <TextV2 className={styles.subTitle} color={themeColor.BASE_40} fontSize={FontSize.SM}>
            {caption.activateExtraFactorSubLabel}
            <TextV2 className={styles.decoration} color={themeColor.PRIMARY_40}>
              *
            </TextV2>
          </TextV2>

          <div className={styles.emailOption}>
            <SimpleDropdown
              wrapperMargin={10}
              options={[
                {
                  caption: OtpContactType.EMAIL,
                  onClick: handleTwoFactorAuthClick(OtpContactType.EMAIL),
                  className: styles.listItem,
                },
                {
                  caption: OtpContactType.PHONE,
                  onClick: handleTwoFactorAuthClick(OtpContactType.PHONE),
                  className: styles.listItem,
                },
              ]}
              triggerElement={
                <DefaultDropdownInput inputClassName={styles.dropdownInput} size={inputSize} selectedOption={selectedVerificationMethod} />
              }
            />

            {isEmailSelected && (
              <div className={styles.emailWrapper}>
                <IconCommon name={IconName.ENVELOPE} className={styles.emailIcon} color={themeColor.BASE_50} size={Size.LARGE} />
                <TextV2 color={themeColor.BASE_20} fontWeight={FontWeight.SEMIBOLD}>
                  {email}
                </TextV2>
              </div>
            )}

            {isPhoneSelected && (
              <div>
                <OnBoardingPhoneInput
                  countryCode={countryCode}
                  value={phoneNumber}
                  size={inputSize}
                  countriesPhones={countriesPhones}
                  countryISO={countryISO}
                  handleOnCountryChange={handleOnCountryChange}
                  onChange={handlePhoneChange}
                />
              </div>
            )}
          </div>
        </>
      )}
    </FormItem>
  );
};
