import React, { type FC, type ReactNode } from "react";

import { PageScrollableArea } from "@components/ScrollableArea/PageScrollableArea";

interface Props {
  HeaderElement: JSX.Element;
  children: ReactNode;
}

export const UserProfileWrapper: FC<Props> = ({ children, HeaderElement }) => (
  <>
    {HeaderElement}
    <PageScrollableArea>{children}</PageScrollableArea>
  </>
);
