import { SubTitle } from "@technis/ui";
import classNames from "classnames";
import React, { type FC, type ReactNode } from "react";

import styles from "./formSubTitle.module.scss";

export interface Props {
  children?: ReactNode;
  className?: string;
}

export const FormSubTitle: FC<Props> = ({ children, className }) => (
  <SubTitle className={classNames(styles.subTitle, styles.form, className)}>{children}</SubTitle>
);
