import { Checkbox_V2, type DropdownElementProps, Size } from "@technis/ui";
import React, { type FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import { dateFormats, languages, themes, timeFormats, timezones } from "@common/configs";
import { type UserData } from "@common/interfaces";

import { FormItem } from "@components/common/Form/FormItem/FormItem";
import { OnboardingContext } from "@components/onboarding/Onboarding/Onboarding";
import { OnboardingDropdownWrapper } from "@components/onboarding/OnboardingDropdownWrapper/OnboardingDropdownWrapper";

import { translation } from "@lang/translation";

import styles from "./styles.module.scss";

export const ProfileSettings: FC = () => {
  const { t } = useTranslation();

  const profileSettingsFormCaptions = {
    languageItemTitle: t(translation.profileSettingsForm.languageItemTitle),
    languageItemDescription: t(translation.profileSettingsForm.languageItemDescription),
    themeItemTitle: t(translation.profileSettingsForm.themeItemTitle),
    themeItemDescription: t(translation.profileSettingsForm.themeItemDescription),
    timezoneItemTitle: t(translation.profileSettingsForm.timezoneItemTitle),
    timezoneItemDescription: t(translation.profileSettingsForm.timezoneItemDescription),
    dateFormatItemTitle: t(translation.profileSettingsForm.dateFormatItemTitle),
    dateFormatItemDescription: t(translation.profileSettingsForm.dateFormatItemDescription),
    checkboxLabel: t(translation.profileSettingsForm.checkboxLabel),
  };

  const {
    languageItemDescription,
    dateFormatItemDescription,
    timezoneItemDescription,
    dateFormatItemTitle,
    themeItemDescription,
    languageItemTitle,
    timezoneItemTitle,
    themeItemTitle,
    checkboxLabel,
  } = profileSettingsFormCaptions;

  const { updateData, dateFormat, language, theme, timezone, timeFormat, setIsDefaultTimezone, isDefaultTimezone } =
    useContext(OnboardingContext);

  const handleOnOptionClick = (option: DropdownElementProps, propertyName: keyof UserData): void => {
    updateData({ propName: propertyName, value: option.value ?? " " });
  };

  const handleOnChecked = (): void => {
    const defaultTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (defaultTimezone !== timezone) {
      updateData({ propName: "timezone", value: defaultTimezone });
    }

    setIsDefaultTimezone(!isDefaultTimezone);
  };

  return (
    <div className={styles.wrapper}>
      <FormItem className={styles.formItemWrapper} title={languageItemTitle} subTitle={languageItemDescription}>
        <OnboardingDropdownWrapper
          handleOnOptionClick={(option): void => handleOnOptionClick(option, "language")}
          options={languages}
          selectedOptionValue={language}
        />
      </FormItem>
      <FormItem className={styles.formItemWrapper} title={themeItemTitle} subTitle={themeItemDescription}>
        <OnboardingDropdownWrapper
          handleOnOptionClick={(option): void => handleOnOptionClick(option, "theme")}
          options={themes}
          selectedOptionValue={theme}
        />
      </FormItem>
      <FormItem className={styles.formItemWrapper} title={timezoneItemTitle} subTitle={timezoneItemDescription}>
        <div className={styles.timezoneWrapper}>
          <Checkbox_V2 checkboxSize={Size.MEDIUM} label={checkboxLabel} isChecked={isDefaultTimezone} onChange={handleOnChecked} />
          <OnboardingDropdownWrapper
            handleOnOptionClick={(option): void => handleOnOptionClick(option, "timezone")}
            options={timezones}
            selectedOptionValue={timezone}
            isDisabled={isDefaultTimezone}
          />
        </div>
      </FormItem>
      <FormItem className={styles.formItemWrapper} title={dateFormatItemTitle} subTitle={dateFormatItemDescription}>
        <div className={styles.dateInputsWrapper}>
          <OnboardingDropdownWrapper
            handleOnOptionClick={(option): void => handleOnOptionClick(option, "dateFormat")}
            options={dateFormats}
            selectedOptionValue={dateFormat}
          />
          <OnboardingDropdownWrapper
            handleOnOptionClick={(option): void => handleOnOptionClick(option, "timeFormat")}
            options={timeFormats}
            selectedOptionValue={timeFormat}
          />
        </div>
      </FormItem>
    </div>
  );
};
