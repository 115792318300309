// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Exdu1lcQ8BUfvkycVnFn {
  padding: 0 0.25em;
}

.vaWnJozFpjmiSJIFYQ12 {
  min-width: 160px;
}`, "",{"version":3,"sources":["webpack://./src/components/tutorial/TutorialWelcomeModal/tutorialWelcomeModal.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".highlightedText {\n  padding: 0 0.25em;\n}\n\n.startButton {\n  min-width: 160px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlightedText": `Exdu1lcQ8BUfvkycVnFn`,
	"startButton": `vaWnJozFpjmiSJIFYQ12`
};
export default ___CSS_LOADER_EXPORT___;
