import { Permissions, type UserRoles } from "@technis/shared";
import {
  type DropdownElementProps,
  IconCommon,
  IconName,
  Logo as TechnisLogo,
  LOGO_TYPE,
  Sidebar_V2,
  type SidebarBodyEntry,
  type SidebarBodyItem,
  type SidebarBodySection,
  SidebarSectionType,
  Size,
} from "@technis/ui";
import { isEmpty } from "lodash";
import React, { type FC, type ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useMatches, useNavigate, useParams } from "react-router-dom";

import { useTracking } from "@hooks/useTracking";

import { TutorialElementId } from "@common/enums";
import { type SideBarTab } from "@common/interfaces/sidebar/sideBarTab";

import { translation } from "@lang/translation";

import { logout } from "@redux/auth/auth.slice";
import { updateExitModalState } from "@redux/exitModal/exitModal.slice";
import { updateOrganizationData } from "@redux/organization/organization.slice";

import { RoutePath } from "@routes/routePath";

import { type OrganizationData, OrganizationService } from "@services/organizationService";
import { type ProfileData } from "@services/profileService";

import { type RootState } from "@store/rootReducer";

import styles from "./sidebar.module.scss";

interface Props {
  avatarSrc?: string;
  email?: string;
  firstName: string;
  footerElements?: ReactNode;
  isAirQualityShown?: boolean | null;
  isCountingShown?: boolean | null;
  isExportDataShown?: boolean;
  isSettingsShown?: boolean;
  isSidebarShrunk?: boolean;
  lastName?: string;
  organizationId?: number;
  profileData?: ProfileData;
  role?: UserRoles;
  sidebarConfig: SideBarTab[];
}

export const Sidebar: FC<Props> = ({
  isAirQualityShown = false,
  isCountingShown = false,
  sidebarConfig,
  email = "",
  firstName,
  lastName = "",
  avatarSrc,
  profileData,
  isExportDataShown = false,
  isSidebarShrunk,
  organizationId,
  footerElements,
  isSettingsShown = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const matches = useMatches();
  const dispatch = useDispatch();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [orgData, setOrgData] = useState<OrganizationData>({ organizationById: { name: "" } });

  const { pageId } = useParams();
  const { name: orgName, logo: orgLogo } = useSelector((state: RootState) => state.organization);

  const hasSensorRight = !!profileData && profileData.profileById.rights?.dashboard?.sensor[Permissions.READ];
  const hasCustomRight = !!profileData && profileData.profileById.rights?.dashboard?.custom[Permissions.READ];
  const isExitModalRendered = useSelector((state: RootState) => state.exitModal.isExitModalRendered);

  const { trackClick, trackingEvents } = useTracking();

  const setExitModalShown = (navigationPath: string): void => {
    dispatch(updateExitModalState({ isExitModalShown: true, navigationPath }));
  };
  const onSidebarLogoClick = (): void => {
    if (isExitModalRendered && location.pathname !== RoutePath.HOME) {
      setExitModalShown(RoutePath.HOME);
      return;
    }
    navigate(RoutePath.HOME);
  };

  const onSidebarItemClick = (itemLink: string): void => {
    switch (itemLink) {
      case RoutePath.HOME: {
        trackClick({ name: trackingEvents.SIDEBAR_HOME_CLICKED });
        break;
      }
      case RoutePath.HELP_CENTER: {
        trackClick({ name: trackingEvents.SIDEBAR_HELP_CENTER_CLICKED });
        break;
      }
      case RoutePath.COUNTING: {
        trackClick({ name: trackingEvents.SIDEBAR_COUNTING_CLICKED });
        break;
      }
      case RoutePath.AIR_QUALITY: {
        trackClick({ name: trackingEvents.SIDEBAR_AIR_QUALITY_CLICKED });
        break;
      }
      case RoutePath.EXPORT_DATA: {
        trackClick({ name: trackingEvents.SIDEBAR_EXPORT });
        break;
      }
      default: {
        break;
      }
    }

    if (isExitModalRendered && location.pathname !== itemLink) {
      setExitModalShown(itemLink);
      return;
    }
    navigate(itemLink);
  };

  const sidebarHeader = {
    logo: orgLogo ? (
      <div className="outerWrapper-customer-logo">
        <img className="generic organizationLogo" src={orgLogo} />
      </div>
    ) : (
      <div className="outerWrapper-technis-logo">
        <TechnisLogo className="generic technisLogo" type={LOGO_TYPE.LOGO_ONLY} />
      </div>
    ),
    onClick: onSidebarLogoClick,
    label: orgName || "Technis",
  };

  useEffect(() => {
    if (organizationId) OrganizationService.organizationData({ organizationId }).then((result) => setOrgData(result.data));
  }, [organizationId]);

  const topSidebarSection: SidebarBodyEntry[] = useMemo(
    () =>
      [
        {
          type: SidebarSectionType.ITEM,
          props: {
            text: t(translation.navigation.homePage),
            link: RoutePath.HOME,
            iconName: IconName.HOUSE,
            isActive: location.pathname === RoutePath.HOME,
          },
        },
        isExportDataShown && {
          type: SidebarSectionType.ITEM,
          props: {
            text: t(translation.navigation.exportPage),
            link: RoutePath.EXPORT_DATA,
            iconName: IconName.FILE_CHART_COLUMN,
            isActive: location.pathname === RoutePath.EXPORT_DATA,
          },
        },
      ].filter(Boolean) as SidebarBodyEntry[],
    [t, location.pathname, isExportDataShown],
  );

  useEffect(() => {
    if (orgData.organizationById) {
      const { name, logo } = orgData.organizationById;
      dispatch(updateOrganizationData({ name, logo }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgData.organizationById]);

  const installationsDashboardsSection: SidebarBodyEntry | undefined = useMemo(() => {
    if (!hasSensorRight || !(isCountingShown || isAirQualityShown)) {
      return;
    }

    return {
      type: SidebarSectionType.SECTION,
      props: {
        text: t(translation.installations.sidebarSection).toUpperCase(),
        sidebarItems: [
          isCountingShown
            ? {
                elementId: TutorialElementId.SIDEBAR_COUNTING_LABEL,
                text: t(translation.installations.countingTitle),
                link: RoutePath.COUNTING,
                iconName: IconName.COUNTING,
              }
            : null,
          isAirQualityShown
            ? {
                text: t(translation.installations.airQualityTitle),
                link: RoutePath.AIR_QUALITY,
                iconName: IconName.WIND,
              }
            : null,
        ].filter(Boolean),
      },
    } as SidebarBodyEntry;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAirQualityShown, isCountingShown, language, hasSensorRight]);

  const customPagesConfig = useMemo(
    (): SidebarBodySection | null =>
      isEmpty(sidebarConfig) || !hasCustomRight
        ? null
        : {
            type: SidebarSectionType.SECTION,
            props: {
              text: t(translation.sidebar.sections.customDashboards),
              sidebarItems: sidebarConfig.map((sidebarItem) => ({
                ...sidebarItem,
                type: SidebarSectionType.ITEM,
              })),
            },
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sidebarConfig, language, hasCustomRight],
  );

  const bottomMenu: SidebarBodyItem[] = [
    ...(isSettingsShown
      ? [
          {
            type: SidebarSectionType.ITEM,
            props: {
              text: t(translation.common.settings),
              iconName: IconName.GEAR,
              link: RoutePath.SETTINGS,
            },
          },
        ]
      : []),
    {
      type: SidebarSectionType.ITEM,
      props: {
        text: t(translation.common.helpCenter),
        iconName: IconName.CIRCLE_QUESTION_MARK_EMPTY,
        link: RoutePath.HELP_CENTER,
      },
    },
    ...(process.env.LINK_ENV === "production"
      ? []
      : [
          {
            type: SidebarSectionType.ITEM,
            props: {
              text: t(translation.common.launchTutorial),
              iconName: IconName.VIDEO_SQUARE,
              link: `${RoutePath.TUTORIAL}`,
            },
          },
        ]),
  ].map((item) => {
    const isActive = matches.some((match) => match.pathname.startsWith(item.props.link || ""));

    return {
      props: {
        ...item.props,
        isActive,
      },
    };
  });

  const bodySections: SidebarBodyEntry[] = useMemo(
    () =>
      [...topSidebarSection, customPagesConfig, installationsDashboardsSection].filter(Boolean).map((item) => {
        if (pageId && item?.type === SidebarSectionType.ITEM) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const isActive = item.props.pageId?.toString() === pageId;

          return {
            props: {
              ...item.props,
              isActive,
            },
          };
        }

        if (item?.type === SidebarSectionType.SECTION) {
          return {
            ...item,
            props: {
              ...item.props,
              sidebarItems: item?.props.sidebarItems.map((sectionItem) => ({
                ...sectionItem,
                isActive: matches.some((match) => match.pathname.startsWith(sectionItem.link || "")),
              })),
            },
          } as SidebarBodySection;
        }

        return item as SidebarBodyEntry;
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [topSidebarSection, customPagesConfig, installationsDashboardsSection, pageId, matches, language],
  );

  const onLogoutClick = (): void => {
    trackClick({ name: trackingEvents.USER_LOGGED_OUT });
    dispatch(logout());
  };

  const profileOptions: DropdownElementProps[] = [
    {
      caption: t(translation.sidebar.profileOptions.userProfile),
      icon: (
        <span className={styles.profileDropdownOptionIcon}>
          <IconCommon name={IconName.USER} size={Size.MEDIUM} />
        </span>
      ),
      className: styles.profileDropdownOption,
      onClick: () => navigate(RoutePath.USER_PROFILE),
    },
  ];

  return (
    <Sidebar_V2
      isSidebarShrunk={isSidebarShrunk}
      header={sidebarHeader}
      bodySections={bodySections}
      bottomMenu={bottomMenu}
      footerConfig={{
        email,
        firstName,
        lastName,
        imageSrc: avatarSrc,
        profileDropdownOptions: profileOptions,
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logoutLabel: t(translation.common.logout),
        onLogoutClick,
        children: footerElements,
      }}
      onHeaderClick={onSidebarLogoClick}
      onSidebarItemClick={onSidebarItemClick}
    />
  );
};
