// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sYikMn0g2GCtI1g24GD3 {
  margin-bottom: 32px;
}
.sYikMn0g2GCtI1g24GD3 :last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 500px) {
  .sYikMn0g2GCtI1g24GD3 {
    margin-bottom: 48px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/onboardingSteps/CreatePassword/createPassword.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAGA;EACE;IACE,mBAAA;EAAF;AACF","sourcesContent":[".formItem {\n  margin-bottom: 32px;\n\n  :last-child {\n    margin-bottom: 0;\n  }\n}\n\n@media screen and (min-width: 500px) {\n  .formItem {\n    margin-bottom: 48px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": `sYikMn0g2GCtI1g24GD3`
};
export default ___CSS_LOADER_EXPORT___;
