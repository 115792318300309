// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dfNKgtXnM5W3OMGPbwlU {
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .dfNKgtXnM5W3OMGPbwlU .IBkLfLzrADZAj3o5cCg9 {
    flex-direction: column;
  }
  .dfNKgtXnM5W3OMGPbwlU .osWQB3ld8BPfLZgqR2pM {
    gap: 8px;
  }
}
.dfNKgtXnM5W3OMGPbwlU .IrbBqXXT6wJ5jemaqhmv > div {
  width: 100%;
}
.dfNKgtXnM5W3OMGPbwlU .IBkLfLzrADZAj3o5cCg9 {
  display: flex;
  gap: 8px;
}
.dfNKgtXnM5W3OMGPbwlU .IBkLfLzrADZAj3o5cCg9 > div {
  width: 100%;
}
.dfNKgtXnM5W3OMGPbwlU .l4q0MQwqlF_Rbc_8C9wj {
  display: flex;
  flex-direction: column;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/onboardingSteps/ProfileSettings/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EAEA,WAAA;AAAF;AAEE;EACE;IACE,sBAAA;EAAJ;EAGE;IACE,QAAA;EADJ;AACF;AAIE;EACE,WAAA;AAFJ;AAKE;EACE,aAAA;EACA,QAAA;AAHJ;AAME;EACE,WAAA;AAJJ;AAOE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AALJ","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 48px;\n\n  width: 100%;\n\n  @media screen and (max-width: 500px) {\n    .dateInputsWrapper {\n      flex-direction: column;\n    }\n\n    .timeZoneWrapper {\n      gap: 8px;\n    }\n  }\n\n  .formItemWrapper > div {\n    width: 100%;\n  }\n\n  .dateInputsWrapper {\n    display: flex;\n    gap: 8px;\n  }\n\n  .dateInputsWrapper > div {\n    width: 100%;\n  }\n\n  .timezoneWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `dfNKgtXnM5W3OMGPbwlU`,
	"dateInputsWrapper": `IBkLfLzrADZAj3o5cCg9`,
	"timeZoneWrapper": `osWQB3ld8BPfLZgqR2pM`,
	"formItemWrapper": `IrbBqXXT6wJ5jemaqhmv`,
	"timezoneWrapper": `l4q0MQwqlF_Rbc_8C9wj`
};
export default ___CSS_LOADER_EXPORT___;
