import { DefaultDropdownInput, type DropdownElementProps, SimpleDropdown, Size } from "@technis/ui";
import React, { type FC, useMemo, useState } from "react";

import { DROPDOWN_WRAPPER_MARGIN } from "@common/constants";

import styles from "./styles.module.scss";
import inputsStyles from "../../../../styles/input.module.scss";

interface OnboardingDropdownWrapperProps {
  handleOnOptionClick: (option: DropdownElementProps) => void;
  isDisabled?: boolean;
  options: DropdownElementProps[];
  selectedOptionValue: string;
}

export const OnboardingDropdownWrapper: FC<OnboardingDropdownWrapperProps> = ({
  options,
  selectedOptionValue,
  handleOnOptionClick,
  isDisabled,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const selectedOption = useMemo(
    () => options.find((option) => option.value === selectedOptionValue) ?? options[0],
    [options, selectedOptionValue],
  );

  const mappedOptions = useMemo(
    () =>
      options.map((option) => {
        const onClick = (): void => handleOnOptionClick(option);
        return { ...option, onClick };
      }),
    [handleOnOptionClick, options],
  );

  const handleOnTriggerClick = (isOpen: boolean): void => {
    setIsOpen(isOpen);
  };

  return (
    <div className={isDisabled ? styles.disabled : ""}>
      <SimpleDropdown
        isWidthSameAsTrigger
        wrapperMargin={DROPDOWN_WRAPPER_MARGIN}
        options={mappedOptions}
        minWidth="271px"
        triggerElement={
          <DefaultDropdownInput
            isOpen={isOpen}
            icon={selectedOption.icon}
            size={Size.LARGE}
            className={inputsStyles.input}
            selectedOption={selectedOption.caption}
          />
        }
        onTriggerClick={handleOnTriggerClick}
      />
    </div>
  );
};
