import React from "react";

export const getMultilineHTML = (text?: string): JSX.Element => (
  <>
    {text?.split("\n").map((textItem, index) => (
      <React.Fragment key={index}>
        {textItem}
        <br />
      </React.Fragment>
    ))}
  </>
);
