// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fPXbPyKVlE0TmNrfGVHa.Oh_WNcVnqOFFgHDC8MBC {
  font-size: 32px;
  line-height: 124%;
}
@media screen and (min-width: 500px) {
  .fPXbPyKVlE0TmNrfGVHa.Oh_WNcVnqOFFgHDC8MBC {
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.002em;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Form/FormTitle/formTitle.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;AACF;AACE;EAJF;IAKI,eAAA;IACA,iBAAA;IACA,uBAAA;EAEF;AACF","sourcesContent":[".form.title {\n  font-size: 32px;\n  line-height: 124%;\n\n  @media screen and (min-width: 500px) {\n    font-size: 36px;\n    line-height: 50px;\n    letter-spacing: 0.002em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `fPXbPyKVlE0TmNrfGVHa`,
	"title": `Oh_WNcVnqOFFgHDC8MBC`
};
export default ___CSS_LOADER_EXPORT___;
