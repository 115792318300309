import { useQuery } from "@apollo/client";
import { DAY_IN_SECONDS, HOUR_IN_SECONDS, MONTH_IN_SECONDS, WEEK_IN_SECONDS } from "@technis/shared";
import { FilledButton, FormElementTypeV2, type FormElementV2, FormV2, Label, Modal, Month, Size, type LegacyFormOption } from "@technis/ui";
import classNames from "classnames";
import { type TFunction } from "i18next";
import { cloneDeep } from "lodash";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

// eslint-disable-next-line import/no-unassigned-import
import "react-datepicker/dist/react-datepicker.css";

import { translation } from "@lang/translation";

import { RetailReportGeneratorService } from "@services/reportGeneratorService";
import { TOP_ZONES_WITH_TAGS, type TopZonesWithTagsResult } from "@services/zoneService";

type FormElement = FormElementV2 & { props: Record<string, unknown> };

const getDatePickerFrom = (translate: TFunction): FormElement => ({
  name: "from",
  type: FormElementTypeV2.DATE_PICKER,
  schema: yup.number().required(),
  props: {
    value: moment().startOf("day").valueOf(),
    label: translate(translation.reportGenerator.from),
    monthNames: Object.values(Month),
    wrapperMargin: 10,
  },
});

const getDatePickerTo = (translate: TFunction): FormElement => ({
  name: "to",
  type: FormElementTypeV2.DATE_PICKER,
  schema: yup.number().required(),
  props: {
    value: moment().endOf("day").valueOf(),
    label: translate(translation.reportGenerator.to),
    monthNames: Object.values(Month),
    wrapperMargin: 10,
  },
});

const getDropDownZone = (translate: TFunction): FormElement => ({
  name: "zones",
  type: FormElementTypeV2.MULTI_DROPDOWN,
  schema: yup.array().min(1).required(),
  props: {
    emptyPlaceholder: "No options",
    wrapperMargin: 5,
    options: [],
    placeholder: translate(translation.reportGenerator.installations),
    components: { DropdownIndicator: null },
  },
});

const getDropDownGranularity = (translate: TFunction): FormElement => {
  const granularityDefaultValue = {
    value: `${HOUR_IN_SECONDS}`,
    caption: `1 ${translate(translation.reportGenerator.hour)}`,
  };
  return {
    name: "granularity",
    type: FormElementTypeV2.DROPDOWN,
    schema: yup
      .object({
        caption: yup.string().required(),
        value: yup.string().required(),
      })
      .required(),
    props: {
      wrapperMargin: 5,
      placeholder: "Granularity",
      options: [
        granularityDefaultValue,
        {
          value: `${HOUR_IN_SECONDS * 3}`,
          caption: `3 ${translate(translation.reportGenerator.hours)}`,
        },
        {
          value: `${HOUR_IN_SECONDS * 6}`,
          caption: `6 ${translate(translation.reportGenerator.hours)}`,
        },
        {
          value: `${DAY_IN_SECONDS}`,
          caption: translate(translation.reportGenerator.daily),
        },
        {
          value: `${WEEK_IN_SECONDS}`,
          caption: translate(translation.reportGenerator.weekly),
        },
        {
          value: `${MONTH_IN_SECONDS}`,
          caption: translate(translation.reportGenerator.monthly),
        },
      ],
      selectedOption: granularityDefaultValue,
    },
  };
};

const getEmailsMultiInput = (translate: TFunction): FormElement => ({
  name: "emailList",
  type: FormElementTypeV2.MULTI_INPUT,
  schema: yup.array().min(1).required(),
  props: {
    id: "email-dropdown-creatable",
    isEmail: true,
    placeholder: translate(translation.reportGenerator.email),
    className: "report-card-form-emails",
  },
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addOptionsToFormProps = (formProps: any, options: any[]): any => {
  const updatedFormProps = cloneDeep(formProps);
  updatedFormProps.props.options = options;
  return updatedFormProps;
};

interface OnSubmitProps {
  emailList: LegacyFormOption[];
  from: number;
  granularity: LegacyFormOption;
  to: number;
  zones: LegacyFormOption[];
}

interface ReportGeneratorProps {
  isOpen: boolean;
  setIsOpen: (props: boolean) => void;
}

export const ReportGenerator = ({ isOpen, setIsOpen }: ReportGeneratorProps): JSX.Element => {
  const { t } = useTranslation();
  const initDatePickerForm = getDatePickerFrom(t);
  const initDatePickerTo = getDatePickerTo(t);
  const initDropDownZone = getDropDownZone(t);
  const initDropDownGranularity = getDropDownGranularity(t);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (): void => setIsOpen(false);

  const onSubmit = async (data: OnSubmitProps): Promise<void> => {
    try {
      setIsLoading(true);

      const { from, to, zones, granularity, emailList } = data;
      const zoneIds = zones.map((zone: { value: string }) => Number(zone.value));
      const emails = emailList.map((email: { value: string }) => email.value);
      const granularityValue = Number(granularity.value);
      await RetailReportGeneratorService.create(from, to, zoneIds, granularityValue, emails);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const SubmitButton = (
    <div className="report-card-button-wrapper">
      <FilledButton
        type="submit"
        text={t(translation.reportGenerator.send)}
        loading={isLoading}
        size={Size.MEDIUM}
        className="report-card-button"
      />
    </div>
  );

  const { data: zoneData } = useQuery<TopZonesWithTagsResult>(TOP_ZONES_WITH_TAGS);

  const topZonesWithTags = zoneData?.topZonesWithTags || [];

  const optionsForCorners = topZonesWithTags.map((zone) => ({
    value: `${zone.id}`,
    caption: zone.installation?.name || `${zone.id}`,
  }));

  const emailsMultiInput = getEmailsMultiInput(t);

  const formItems = [
    initDatePickerForm,
    initDatePickerTo,
    addOptionsToFormProps(initDropDownZone, optionsForCorners),
    initDropDownGranularity,
    emailsMultiInput,
  ];
  return (
    <div>
      <Modal
        shown={isOpen}
        size={Size.EXTRA_LARGE}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <div className="report-card">
          <div className="report-card-title">{t(translation.reportGenerator.title)}</div>

          <Label className="report-card-subtext">{t(translation.reportGenerator.subTitle)}</Label>

          <FormV2
            SubmitElement={SubmitButton}
            containerClassName="report-card-form-container"
            validationMode="onBlur"
            blockClassName="report-card-form-block"
            items={formItems}
            onSubmit={onSubmit}
          >
            <Label className={classNames("report-card-subtext", "report-card-bottom-subtitle")}>
              {t(translation.reportGenerator.bottomSubTitle)}
            </Label>
          </FormV2>
        </div>
      </Modal>
    </div>
  );
};
