// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QQPGYuL7ylsUKqnggzti {
  display: flex;
  padding: 28px 24px;
}
.QQPGYuL7ylsUKqnggzti .btXLww3ZioBhVuyarM28 {
  flex-shrink: 0;
  width: 27px;
  height: 24px;
  margin-right: 16px;
}

.Hn9HTOZZTBgGoGcNpYr0 {
  display: none;
}

@media screen and (min-width: 500px) {
  .QQPGYuL7ylsUKqnggzti {
    display: none;
  }
  .Hn9HTOZZTBgGoGcNpYr0 {
    display: flex;
    padding: 28px 120px;
  }
  .Hn9HTOZZTBgGoGcNpYr0 .btXLww3ZioBhVuyarM28 {
    flex-shrink: 0;
    width: 130px;
    height: 28px;
    margin-right: 128px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/StepBar/stepBar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,kBAAA;AAAF;AAEE;EACE,cAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;AADJ;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE;IACE,aAAA;EAFF;EAKA;IACE,aAAA;IAEA,mBAAA;EAJF;EAME;IACE,cAAA;IAEA,YAAA;IACA,YAAA;IACA,mBAAA;EALJ;AACF","sourcesContent":[".barMobile {\n  display: flex;\n\n  padding: 28px 24px;\n\n  .logo {\n    flex-shrink: 0;\n\n    width: 27px;\n    height: 24px;\n    margin-right: 16px;\n  }\n}\n\n.bar {\n  display: none;\n}\n\n@media screen and (min-width: 500px) {\n  .barMobile {\n    display: none;\n  }\n\n  .bar {\n    display: flex;\n\n    padding: 28px 120px;\n\n    .logo {\n      flex-shrink: 0;\n\n      width: 130px;\n      height: 28px;\n      margin-right: 128px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"barMobile": `QQPGYuL7ylsUKqnggzti`,
	"logo": `btXLww3ZioBhVuyarM28`,
	"bar": `Hn9HTOZZTBgGoGcNpYr0`
};
export default ___CSS_LOADER_EXPORT___;
