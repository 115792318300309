// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._QBwcLn9V4BC68V6vHcp {
  display: flex;
  flex-direction: column;
}
._QBwcLn9V4BC68V6vHcp .b_gZS5D45DtHrArzRaVl {
  padding-left: 4px;
}
._QBwcLn9V4BC68V6vHcp .XJ1nq3Z_MMLYl2_yyX6C {
  display: none;
}
._QBwcLn9V4BC68V6vHcp .cisy8Hz2kT8L1QsDhZL9 {
  margin-bottom: 12px;
}
._QBwcLn9V4BC68V6vHcp .cisy8Hz2kT8L1QsDhZL9 .q8uwMUYud2vXZGIZt4xB {
  line-height: 172%;
  letter-spacing: 0.0025em;
}
._QBwcLn9V4BC68V6vHcp .cisy8Hz2kT8L1QsDhZL9 .Wubyms9BCHlrnNVGc_47 {
  display: none;
}

.lKmeLRGI3snbnD8tXyOP {
  padding-left: 8px;
}

@media screen and (min-width: 500px) {
  ._QBwcLn9V4BC68V6vHcp {
    flex-direction: row;
    column-gap: 16px;
  }
  ._QBwcLn9V4BC68V6vHcp .cisy8Hz2kT8L1QsDhZL9 {
    display: none;
  }
  ._QBwcLn9V4BC68V6vHcp .XJ1nq3Z_MMLYl2_yyX6C {
    display: block;
    width: 296px;
  }
  ._QBwcLn9V4BC68V6vHcp .XJ1nq3Z_MMLYl2_yyX6C .q8uwMUYud2vXZGIZt4xB {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  ._QBwcLn9V4BC68V6vHcp .XJ1nq3Z_MMLYl2_yyX6C .Wubyms9BCHlrnNVGc_47 {
    display: block;
  }
  ._QBwcLn9V4BC68V6vHcp .oaRX0UpmYFi93l2RnpRQ {
    flex: 0 1 100%;
    overflow: hidden;
  }
}
.QtpV4qjrN00QikqV7bkk {
  padding-left: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Form/FormItem/formItem.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ;AAKI;EACE,iBAAA;EACA,wBAAA;AAHN;AAMI;EACE,aAAA;AAJN;;AASA;EACE,iBAAA;AANF;;AASA;EACE;IACE,mBAAA;IAEA,gBAAA;EAPF;EASE;IACE,aAAA;EAPJ;EAUE;IACE,cAAA;IAEA,YAAA;EATJ;EAWI;IACE,aAAA;IACA,mBAAA;IAEA,kBAAA;EAVN;EAaI;IACE,cAAA;EAXN;EAeE;IACE,cAAA;IAEA,gBAAA;EAdJ;AACF;AAkBA;EACE,kBAAA;AAhBF","sourcesContent":["@import \"styles/variable\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n\n  .decoration {\n    padding-left: 4px;\n  }\n\n  .label {\n    display: none;\n  }\n\n  .labelMobile {\n    margin-bottom: 12px;\n\n    .title {\n      line-height: 172%;\n      letter-spacing: 0.0025em;\n    }\n\n    .subTitle {\n      display: none;\n    }\n  }\n}\n\n.tooltip {\n  padding-left: 8px;\n}\n\n@media screen and (min-width: 500px) {\n  .container {\n    flex-direction: row;\n\n    column-gap: 16px;\n\n    .labelMobile {\n      display: none;\n    }\n\n    .label {\n      display: block;\n\n      width: 296px;\n\n      .title {\n        display: flex;\n        align-items: center;\n\n        margin-bottom: 8px;\n      }\n\n      .subTitle {\n        display: block;\n      }\n    }\n\n    .childrenWrapper {\n      flex: 0 1 100%;\n\n      overflow: hidden;\n    }\n  }\n}\n\n.childrenPadding {\n  padding-left: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_QBwcLn9V4BC68V6vHcp`,
	"decoration": `b_gZS5D45DtHrArzRaVl`,
	"label": `XJ1nq3Z_MMLYl2_yyX6C`,
	"labelMobile": `cisy8Hz2kT8L1QsDhZL9`,
	"title": `q8uwMUYud2vXZGIZt4xB`,
	"subTitle": `Wubyms9BCHlrnNVGc_47`,
	"tooltip": `lKmeLRGI3snbnD8tXyOP`,
	"childrenWrapper": `oaRX0UpmYFi93l2RnpRQ`,
	"childrenPadding": `QtpV4qjrN00QikqV7bkk`
};
export default ___CSS_LOADER_EXPORT___;
