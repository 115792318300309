import { RegularTab, type RegularTabConfig } from "@technis/ui";
import React, { type FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { type RoutePath } from "@routes/routePath";

import styles from "./tabs.module.scss";

export interface TabsConfig {
  routePath: RoutePath | string;
  text: string;
}

interface TabsProps {
  tabs: TabsConfig[];
}

export const Tabs: FC<TabsProps> = ({ tabs }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const checkIsTabActive = (routePath: RoutePath): boolean => pathname === routePath;

  const tabsList: RegularTabConfig[] = tabs.map(({ text, routePath }) => ({
    onClick: () => navigate(routePath),
    text,
    active: checkIsTabActive(routePath as RoutePath),
  }));

  return (
    <div className={styles.tabsWrapper}>
      {tabsList.map(({ text, active, onClick, routeLink }) => (
        <RegularTab key={text} text={text} active={active} routeLink={routeLink} onClick={onClick} />
      ))}
    </div>
  );
};
