import { useMutation } from "@apollo/client";
import { CountKind, type Organization } from "@technis/shared";
import { FilledButton, Size, ToastType, Variant } from "@technis/ui";
import isEqual from "lodash/isEqual";
import isFunction from "lodash/isFunction";
import React, { type FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTracking } from "@hooks/useTracking";

import { AlertDetail } from "@common/enums";
import { mapNotificationsToActions, mapNotificationsToPlanning } from "@common/helpers/alert";
import { isFormDataValid } from "@common/helpers/validation";

import { ExitPrompt } from "@components/ExitPrompt/ExitPrompt";

import { translation } from "@lang/translation";

import { clearAlertDetails, initialState as alertInitialState } from "@redux/alert/alert.slice";
import { updateExitModalState } from "@redux/exitModal/exitModal.slice";
import { checkValidationState } from "@redux/formValidation/formValidation.slice";
import { displayToast, displayToastError } from "@redux/toast/toast.slice";

import { RoutePath } from "@routes/routePath";

import { CREATE_ALERT, UPDATE_ALERT_WITH_DETAILS } from "@services/alertService";

import { type RootState } from "@store/rootReducer";

import styles from "./alertActions.module.scss";

interface Props {
  isNewAlert?: boolean;
  organizationId: Organization["id"];
}

const BUTTON_SIZE = Size.MEDIUM;

export const AlertActions: FC<Props> = ({ isNewAlert = false, organizationId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { trackClick, trackingEvents } = useTracking();

  const alert = useSelector((state: RootState) => state.alert);

  const { validationFields } = useSelector((state: RootState) => state.formValidation);
  const isExitModalRendered = useSelector((state: RootState) => state.exitModal.isExitModalRendered);
  const user = useSelector((state: RootState) => state.user);

  const [initialState, setInitialState] = useState(alertInitialState);
  const isFieldChanged = useMemo(
    () => Boolean((isNewAlert || initialState[AlertDetail.ID]) && !isEqual(alert, initialState)),
    [alert, initialState, isNewAlert],
  );

  const { save, cancel } = translation.actions;
  const { saveModalText, saveTitle } = translation.settings.alerts.modal;

  const navigateToAlertsList = (): void => {
    navigate(RoutePath.SETTINGS_ALERTS, { replace: true });
  };

  const [createAlertMutation, { loading: isCreateAlertLoading, error: createAlertError }] = useMutation(CREATE_ALERT);
  const [updateAlertMutation, { loading: isUpdateAlertLoading, error: updateAlertError }] = useMutation(UPDATE_ALERT_WITH_DETAILS);

  const isLoading = isCreateAlertLoading || isUpdateAlertLoading;

  const createAlert = async (): Promise<void> => {
    const actions = mapNotificationsToActions({
      notifications: alert[AlertDetail.NOTIFICATIONS],
      isIdSkipped: true,
    });

    await createAlertMutation({
      variables: {
        name: alert[AlertDetail.TITLE],
        actionsToAdd: actions,
        conditionsToAdd: {
          ...alert[AlertDetail.CONDITION],
          eventId: alert[AlertDetail.EVENT]?.id,
          maxTrigger: 0,
        },
        organizationId,
        planningToAdd: mapNotificationsToPlanning({ notifications: alert[AlertDetail.NOTIFICATIONS] }),
      },
    }).then(() => {
      trackClick({
        name: trackingEvents.ALERT_CREATED,
        notificationTypes: actions.map((notification) => notification?.type),
        notificationNumber: actions.length,
        conditionType: alert[AlertDetail.CONDITION].type,
        userId: user?.id,
        organizationId: user?.organizationIds?.[0],
        timestamp: Date.now(),
      });
    });
    dispatch(clearAlertDetails());
    navigateToAlertsList();
    dispatch(
      displayToast({
        text: t(translation.toast.createAlertSuccess),
        type: ToastType.SUCCESS,
      }),
    );
  };

  const updateAlert = async (): Promise<void> => {
    await updateAlertMutation({
      variables: {
        id: Number(alert[AlertDetail.ID]),
        name: alert[AlertDetail.TITLE],
        actions: mapNotificationsToActions({ notifications: alert[AlertDetail.NOTIFICATIONS] }),
        conditions: [
          {
            ...alert[AlertDetail.CONDITION],
            eventId: alert[AlertDetail.EVENT]?.id,
            kinds: [CountKind.PERSON],
            maxTrigger: 0,
          },
        ],
        organizationId,
        planning: {
          ...mapNotificationsToPlanning({
            notifications: alert[AlertDetail.NOTIFICATIONS],
            contacts: alert[AlertDetail.NOTIFICATION_CONTACTS],
          }),
          id: alert[AlertDetail.PLANNING_ID],
        },
      },
    });
    dispatch(clearAlertDetails());
    navigateToAlertsList();
    dispatch(
      displayToast({
        text: t(translation.toast.updateAlertSuccess),
        type: ToastType.SUCCESS,
      }),
    );
  };

  const handleSave = async (): Promise<boolean> => {
    dispatch(checkValidationState());

    if (!isFormDataValid(validationFields)) {
      return false;
    }

    if (isNewAlert) {
      await createAlert();
      return true;
    }

    await updateAlert();
    return true;
  };

  const handleCancelClick = (): void => {
    if (isExitModalRendered) {
      dispatch(updateExitModalState({ isExitModalShown: true, navigationPath: RoutePath.SETTINGS_ALERTS }));
      return;
    }

    navigate(RoutePath.SETTINGS_ALERTS);
  };

  const onApplyButtonClick = async (handleNavigate?: () => void): Promise<void> => {
    const isSavedSuccessfully = await handleSave();

    if (isSavedSuccessfully && isFunction(handleNavigate)) {
      handleNavigate();
    }
  };

  useEffect(() => {
    if (createAlertError || updateAlertError) {
      dispatch(displayToastError(t(translation.errors.unHandled)));
    }
  }, [createAlertError, dispatch, t, updateAlertError]);

  useEffect(() => {
    if (!isNewAlert && !initialState[AlertDetail.ID]) {
      setInitialState(alert);
    }
  }, [alert, initialState, isNewAlert]);

  return (
    <ExitPrompt
      isDiscardButtonShown
      approveButtonText={t(save)}
      isFieldChanged={isFieldChanged}
      text={t(saveModalText)}
      title={t(saveTitle)}
      onSaveButtonClick={onApplyButtonClick}
    >
      <>{t(translation.settings.alerts.modal.saveModalText)}</>
      <div className={styles.wrapper}>
        <FilledButton colorVariant={Variant.WARN} size={BUTTON_SIZE} text={t(cancel)} onClick={handleCancelClick} />

        <FilledButton size={BUTTON_SIZE} text={t(save)} loading={isLoading} onClick={handleSave} />
      </div>
    </ExitPrompt>
  );
};
