import { OtpContactType } from "@technis/shared";
import { CodeInput, ErrorMessages, FlatButton, Modal, Size } from "@technis/ui";
import React from "react";
import { useTranslation } from "react-i18next";

import { OTP_LENGTH } from "@common/constants/otp";
import { useDesktopResolution, useTimer } from "@common/hooks";

import { ServiceModalHeader } from "@components/header";

import { translation } from "@lang/translation";

import styles from "./styles.module.scss";

interface VerificationFormCaptions {
  defaultSubTitle: {
    text: string;
  };
  defaultTitle: {
    text: string;
  };
  resendButton: {
    text: string;
  };
}

type Props = {
  className?: string;
  handleOnModalClose: () => void;
  handleResendCode: () => void;
  hasWrapper?: boolean;
  invalidCodeError?: string;
  isModalShown: boolean;
  loading?: boolean;
  setOtp: (otp: string) => void;
  subTitle?: string;
  title?: string;
  verificationContact: OtpContactType;
};

export const FactorVerificationModal = ({
  verificationContact,
  isModalShown,
  handleOnModalClose,
  setOtp,
  handleResendCode,
  loading,
  title,
  subTitle,
  className,
  hasWrapper = true,
  invalidCodeError,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const errorMessage = invalidCodeError ? [invalidCodeError] : [];
  const getDefaultCaptions = (verificationContact: OtpContactType, isDesktopResolution: boolean): VerificationFormCaptions => {
    const contact = verificationContact === OtpContactType.PHONE ? "sms" : "email";
    const titleKey = isDesktopResolution ? translation.loginOtp.header : translation.loginOtp.headerMobile;

    return {
      defaultTitle: {
        text: t(titleKey, { contact }),
      },
      defaultSubTitle: {
        text: t(translation.loginOtp.subtitle, { contact }),
      },
      resendButton: {
        text: t(translation.loginOtp.resendButtonText, { contact }),
      },
    };
  };

  const { isDesktopResolution } = useDesktopResolution();
  const { start: startResendTimer, timer, isFinished, resetTimer } = useTimer();

  const { defaultTitle, defaultSubTitle, resendButton } = getDefaultCaptions(verificationContact, isDesktopResolution);

  const resendCode = (): void => {
    startResendTimer();
    handleResendCode();
  };

  const onModalClose = (): void => {
    resetTimer();
    handleOnModalClose();
  };

  const handleSetOtp = (otp: string): void => {
    if (otp.length === OTP_LENGTH) {
      setOtp(otp);
    }
  };

  const modalTitle = title ?? defaultTitle.text;
  const modalSubTitle = subTitle ?? defaultSubTitle.text;
  const buttonText = isFinished ? resendButton.text : timer.toString();

  return (
    <Modal hasWrapper={hasWrapper} className={className} size={Size.MEDIUM} shown={isModalShown} onClose={onModalClose}>
      <div className={styles.verificationModalWrapper}>
        <ServiceModalHeader title={modalTitle} subTitle={modalSubTitle} />
        <div className={styles.codeInputWrapper}>
          <CodeInput quantity={OTP_LENGTH} size={isDesktopResolution ? Size.MEDIUM : Size.SMALL} onChange={handleSetOtp} />
          <div className={styles.errorWrapper}>
            <ErrorMessages errorMessagePaddings="16px 0 0" messages={errorMessage} />
          </div>
        </div>
        <div className={styles.sendButtonWrapper}>
          <FlatButton text={buttonText} disabled={loading || timer > 0} loading={loading} size={Size.MEDIUM} onClick={resendCode} />
        </div>
      </div>
    </Modal>
  );
};
