import { useQuery } from "@apollo/client";
import { ToastType } from "@technis/ui";
import React, { createContext, type FC, type PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { PageLoader } from "@components/PageLoader";
import { UsersManagementBreadcrumbsController } from "@components/Settings/UsersManagement/UsersManagementBreadcrumbsController/UsersManagementBreadcrumbsController";

import { translation } from "@lang/translation";

import { displayToast } from "@redux/toast/toast.slice";

import { USER_BY_ID, type UserById, type UserData } from "@services/userService";

interface ContextArguments {
  onUserUpdate: (user: UserData) => void;
  user: UserData;
}

export const UserManagementDataContext = createContext<ContextArguments>({} as ContextArguments);

export const UserManagementDataWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { id: userId } = useParams();
  const { t } = useTranslation();
  const [user, setUser] = useState<UserData>();

  const {
    data: userData,
    loading: isLoading,
    error,
  } = useQuery<UserById>(USER_BY_ID, {
    variables: {
      userId,
    },
    skip: !userId,
  });

  useEffect(() => {
    const user = userData?.userById;
    if (user) {
      setUser(user);
    }
  }, [userData?.userById]);

  const handleUserUpdate = (user: UserData): void => {
    setUser(user);
  };

  if (error) {
    displayToast({
      text: t(translation.errors.unHandled),
      type: ToastType.ERROR,
    });
  }

  if (isLoading || !user) {
    return <PageLoader />;
  }

  return (
    <UserManagementDataContext.Provider value={{ user, onUserUpdate: handleUserUpdate }}>
      <UsersManagementBreadcrumbsController user={user} />
      {children}
    </UserManagementDataContext.Provider>
  );
};
