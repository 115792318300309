// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PfGHrLa3KeRiC69s96XV {
  margin-bottom: 40px;
}
@media screen and (min-width: 500px) {
  .PfGHrLa3KeRiC69s96XV {
    margin-bottom: 80px;
  }
  .PfGHrLa3KeRiC69s96XV .RFem18Ubs7qnANbx7Pw_ {
    margin-bottom: 8px;
  }
}
.PfGHrLa3KeRiC69s96XV .RFem18Ubs7qnANbx7Pw_ {
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/OnboardingHeader/onboardingHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EAHF;IAII,mBAAA;EAEF;EAAE;IACE,kBAAA;EAEJ;AACF;AACE;EACE,mBAAA;AACJ","sourcesContent":[".wrapper {\n  margin-bottom: 40px;\n\n  @media screen and (min-width: 500px) {\n    margin-bottom: 80px;\n\n    .title {\n      margin-bottom: 8px;\n    }\n  }\n\n  .title {\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PfGHrLa3KeRiC69s96XV`,
	"title": `RFem18Ubs7qnANbx7Pw_`
};
export default ___CSS_LOADER_EXPORT___;
