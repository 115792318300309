// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gnbmNCM7p2fbMN9XmKfu {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.FdYfSDHrDi1fEl7cuUe6 {
  text-align: start;
}

.BoiRsNH7etEgKo88oGIf.BoiRsNH7etEgKo88oGIf {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/RegularModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,yBAAA;EACA,mBAAA;EAEA,WAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,WAAA;AAAF","sourcesContent":[".approveButtons {\n  display: flex;\n  gap: 30px;\n  justify-content: flex-end;\n  align-items: center;\n\n  width: 100%;\n}\n\n.textNotCentered {\n  text-align: start;\n}\n\n.button.button {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"approveButtons": `gnbmNCM7p2fbMN9XmKfu`,
	"textNotCentered": `FdYfSDHrDi1fEl7cuUe6`,
	"button": `BoiRsNH7etEgKo88oGIf`
};
export default ___CSS_LOADER_EXPORT___;
