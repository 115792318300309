import { ActionType } from "@technis/shared";
import { v4 as uuid } from "uuid";

import { type AlertNotification } from "@common/interfaces/alert";

export const getInitialNotificationData = (): AlertNotification => ({
  id: uuid(),
  type: ActionType.EMAIL,
  msgTitle: "",
  msgContent: "",
  recipients: [],
});
