// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tVO_AEedlvE9ZIpmeEXe {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.tVO_AEedlvE9ZIpmeEXe .jJvbpzmYBLtcWNv8BWH5 {
  margin-top: 32px;
}
.tVO_AEedlvE9ZIpmeEXe .SBc9w8LpdL8I0656024V {
  margin-top: 40px;
}

.E8vJ0QWNzKvLDO1gzXni {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/login/LoginVerificationModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EAEA,gBAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;;AAKA;EACE,aAAA;EACA,uBAAA;AAFF","sourcesContent":[".verificationModalWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  overflow: hidden;\n\n  .codeInputWrapper {\n    margin-top: 32px;\n  }\n\n  .sendButtonWrapper {\n    margin-top: 40px;\n  }\n}\n\n.errorWrapper {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationModalWrapper": `tVO_AEedlvE9ZIpmeEXe`,
	"codeInputWrapper": `jJvbpzmYBLtcWNv8BWH5`,
	"sendButtonWrapper": `SBc9w8LpdL8I0656024V`,
	"errorWrapper": `E8vJ0QWNzKvLDO1gzXni`
};
export default ___CSS_LOADER_EXPORT___;
