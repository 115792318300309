import { DefaultInput, IconCommon, IconName, Size } from "@technis/ui";
import { isEmpty } from "lodash";
import React, { type ChangeEvent, type FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FormItem, Necessities } from "@components/common/Form/FormItem/FormItem";
import { ImagePicker } from "@components/onboarding/ImagePicker/ImagePicker";
import { OnboardingContext } from "@components/onboarding/Onboarding/Onboarding";

import { translation } from "@lang/translation";

import styles from "./styles.modules.scss";
import inputStyles from "../../../../../styles/input.module.scss";

export const OrganizationSettings: FC = () => {
  const { t } = useTranslation();

  const organizationFormCaptions = {
    organizationItemTitle: t(translation.organizationForm.organizationItemTitle),
    organizationItemDescription: t(translation.organizationForm.organizationItemDescription),
    addressItemTitle: t(translation.organizationForm.addressItemTitle),
    addressItemDescription: t(translation.organizationForm.addressItemDescription),
    logoTitle: t(translation.organizationForm.logoTitle),
    logoDescription: t(translation.organizationForm.logoDescription),
    addressPlaceholder: t(translation.organizationForm.addressPlaceholder),
    organizationPlaceholder: t(translation.organizationForm.organizationPlaceholder),
  };

  const {
    organizationItemTitle,
    organizationItemDescription,
    addressItemDescription,
    addressItemTitle,
    logoDescription,
    logoTitle,
    addressPlaceholder,
    organizationPlaceholder,
  } = organizationFormCaptions;

  const { updateData, updateFormFilledState, organizationName, organizationAddress, organizationLogo } = useContext(OnboardingContext);

  useEffect(() => {
    updateFormFilledState(!isEmpty(organizationName));
  }, [organizationName, updateFormFilledState]);

  const handleOnImagePickerChange = async (imageSource: string): Promise<void> => {
    updateData({ propName: "organizationLogo", value: imageSource });
  };

  const handleOrganizationNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateData({ propName: "organizationName", value: event.target.value });
  };

  const handleOrganizationAddressChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateData({ propName: "organizationAddress", value: event.target.value });
  };

  return (
    <div className={styles.wrapper}>
      <FormItem
        className={styles.itemWrapper}
        title={organizationItemTitle}
        subTitle={organizationItemDescription}
        necessities={Necessities.REQUIRED}
      >
        <div className={styles.inputWrapper}>
          <DefaultInput
            size={Size.LARGE}
            value={organizationName}
            placeholder={organizationPlaceholder}
            className={inputStyles.input}
            onChange={handleOrganizationNameChange}
          />
        </div>
      </FormItem>
      <FormItem
        className={styles.inputWrapper}
        title={addressItemTitle}
        subTitle={addressItemDescription}
        necessities={Necessities.OPTIONAL}
      >
        <div className={styles.inputWrapper}>
          <DefaultInput
            placeholder={addressPlaceholder}
            value={organizationAddress}
            prependedElement={<IconCommon name={IconName.LOCATION_DOT_1} size={Size.MEDIUM} />}
            size={Size.LARGE}
            className={inputStyles.input}
            onChange={handleOrganizationAddressChange}
          />
        </div>
      </FormItem>
      <FormItem className={styles.inputWrapper} title={logoTitle} subTitle={logoDescription} necessities={Necessities.OPTIONAL}>
        <ImagePicker imageSrc={organizationLogo} onImagePickerChange={handleOnImagePickerChange} />
      </FormItem>
    </div>
  );
};
