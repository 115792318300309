// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qzQB_OaYVW0xIrG1POYK {
  z-index: 7;
  min-width: 676px;
  padding-top: 24px;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/tutorial/TutorialSkipModal/tutorialSkipModal.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EAEA,gBAAA;EACA,iBAAA;EACA,sBAAA;AAAF","sourcesContent":[".modalWrapper {\n  z-index: 7;\n\n  min-width: 676px;\n  padding-top: 24px;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `qzQB_OaYVW0xIrG1POYK`
};
export default ___CSS_LOADER_EXPORT___;
