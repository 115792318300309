import { useMutation } from "@apollo/client";
import { AuthErrors, OtpContactType } from "@technis/shared";
import React, { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useDeepCompareEffect } from "react-use";

import { VERIFY_INVITATION_CONTACT } from "@graphql/mutation/invitation";

import { OTP_LENGTH } from "@common/constants/otp";

import { FactorVerificationModal } from "@components/login/LoginVerificationModal/LoginVerificationModal";

import { translation } from "@lang/translation";

import { displayToastError } from "@redux/toast/toast.slice";

interface Props {
  hide: () => void;
  isShown?: boolean;
  onVerificationSuccess: () => void;
  resendCode: () => void;
  token: string;
}

export const PhoneVerification: FC<Props> = ({ isShown = false, hide, token, onVerificationSuccess, resendCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [verifyInvitationContact, { loading: isLoading, error }] = useMutation(VERIFY_INVITATION_CONTACT);
  const [invalidCodeErrorMessage, setInvalidCodeErrorMessage] = useState<string>();

  useDeepCompareEffect(() => {
    if (!error) {
      return;
    }

    if (error.message === AuthErrors.INVALID_OTP) {
      setInvalidCodeErrorMessage(t(translation.otp.invalidCode));
      return;
    }

    dispatch(displayToastError(t(translation.errors.unHandled)));
  }, [dispatch, error]);

  const handlePasswordSet = async (otp: string): Promise<void> => {
    if (otp.length !== OTP_LENGTH) {
      return;
    }

    await verifyInvitationContact({
      variables: {
        token,
        otp,
      },
    });
    onVerificationSuccess();
  };

  if (!isShown) {
    return null;
  }

  const handleResendCode = (): void => {
    resendCode();
  };

  return (
    <FactorVerificationModal
      isModalShown
      title={t(translation.onboarding.phoneVerificationTitle)}
      subTitle={t(translation.onboarding.phoneVerificationSubTitle)}
      handleOnModalClose={hide}
      handleResendCode={handleResendCode}
      loading={isLoading}
      verificationContact={OtpContactType.PHONE}
      setOtp={handlePasswordSet}
      invalidCodeError={invalidCodeErrorMessage}
    />
  );
};
