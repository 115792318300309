import { PasswordInputV2, Size } from "@technis/ui";
import classNames from "classnames";
import React, { type ChangeEvent, type FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import { FormItem, PasswordValidationContext, PasswordValidationWrapper } from "@components/common";
import { Necessities } from "@components/common/Form/FormItem/FormItem";
import { OnboardingContext } from "@components/onboarding/Onboarding/Onboarding";

import { translation } from "@lang/translation";

import styles from "./createPassword.module.scss";
import inputStyles from "../../../../../styles/input.module.scss";

export const CreatePassword: FC = () => {
  const { t } = useTranslation();

  const caption = {
    passwordTitle: t(translation.onboarding.steps.password.passwordTitle),
    passwordSubTitle: t(translation.onboarding.steps.password.passwordSubTitle),
    confirmPasswordTitle: t(translation.onboarding.steps.password.confirmPasswordTitle),
    confirmPasswordSubTitle: t(translation.onboarding.steps.password.confirmPasswordSubTitle),
    passwordPlaceholder: t(translation.onboarding.steps.password.passwordPlaceholder),
  };

  const { updateErrorMessage, updateFormFilledState, passwordConfirmation, passwordNew, updateData } = useContext(OnboardingContext);

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateData({ propName: "passwordNew", value: event.target.value });
  };

  const handlePasswordConfirmationChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateData({ propName: "passwordConfirmation", value: event.target.value });
  };

  return (
    <div>
      <PasswordValidationWrapper
        updateErrorMessage={updateErrorMessage}
        updateFormFilledState={updateFormFilledState}
        passwordNew={passwordNew}
        passwordConfirmation={passwordConfirmation}
      >
        <PasswordValidationContext.Consumer>
          {({ registerPasswordNew, registerPasswordConfirmation }): JSX.Element => (
            <>
              <FormItem
                className={styles.formItem}
                necessities={Necessities.REQUIRED}
                title={caption.passwordTitle}
                subTitle={caption.passwordSubTitle}
              >
                <PasswordInputV2
                  {...registerPasswordNew}
                  size={Size.LARGE}
                  placeholder={caption.passwordPlaceholder}
                  className={inputStyles.input}
                  value={passwordNew}
                  onChange={handlePasswordChange}
                />
              </FormItem>
              <FormItem
                className={styles.formItem}
                necessities={Necessities.REQUIRED}
                title={caption.confirmPasswordTitle}
                subTitle={caption.confirmPasswordSubTitle}
              >
                <PasswordInputV2
                  {...registerPasswordConfirmation}
                  size={Size.LARGE}
                  placeholder={caption.passwordPlaceholder}
                  className={classNames(inputStyles.input, inputStyles.form)}
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                />
              </FormItem>
            </>
          )}
        </PasswordValidationContext.Consumer>
      </PasswordValidationWrapper>
    </div>
  );
};
