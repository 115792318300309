import React, { type FC, type ReactNode } from "react";
import { useParams } from "react-router-dom";

import { type RoutePath } from "@routes/routePath";

export type GetUserRouteName = (routePath: RoutePath) => string;

interface Options {
  getUserRouteName: GetUserRouteName;
  userId: string;
}

interface Props {
  children: (options: Options) => ReactNode;
}

export const UsersManagementParameters: FC<Props> = ({ children }) => {
  const { id: userId = "" } = useParams();
  const getUserRouteName = (routePath: RoutePath): string => routePath.replace(":id", userId);

  return <>{children({ userId, getUserRouteName })}</>;
};
