import { yupResolver } from "@hookform/resolvers/yup";
import { PASSWORD_POLICY } from "@technis/shared";
import React, { createContext, type FC, type ReactNode, useEffect, useMemo } from "react";
import { useForm, type UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { areFieldsFilled } from "@common/helpers";

import { translation } from "@lang/translation";

export interface Props {
  children?: ReactNode;
  passwordConfirmation: string;
  passwordNew: string;
  updateErrorMessage: (message: string) => void;
  updateFormFilledState: (isFilled: boolean) => void;
}

interface PasswordValidationContextValue {
  registerPasswordConfirmation: UseFormRegisterReturn<"passwordConfirmation">;
  registerPasswordNew: UseFormRegisterReturn<"passwordNew">;
}

export const PasswordValidationContext = createContext<PasswordValidationContextValue>({} as PasswordValidationContextValue);

export const PasswordValidationWrapper: FC<Props> = ({
  children,
  updateErrorMessage,
  updateFormFilledState,
  passwordNew,
  passwordConfirmation,
}) => {
  const { t } = useTranslation();
  const validationRules = yup
    .string()
    .min(8, t(translation.validation.passwordLengthError))
    .matches(PASSWORD_POLICY, t(translation.validation.passwordPolicyError))
    .required(t(translation.validation.requiredField));

  const passwordSchema = yup
    .object({
      passwordNew: validationRules,
      passwordConfirmation: validationRules,
    })
    .required();

  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(passwordSchema),
    defaultValues: {
      passwordNew,
      passwordConfirmation,
    },
  });

  const isPasswordsEqual = passwordNew === passwordConfirmation;

  const errorMessage = useMemo((): string | undefined => {
    if (errors.passwordNew) {
      return errors.passwordNew.message;
    }

    if (!isPasswordsEqual) {
      return t(translation.validation.passwordsNotEqual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.passwordNew, isPasswordsEqual]);

  useEffect(() => {
    updateErrorMessage(errorMessage || "");

    return () => updateErrorMessage("");
  }, [errorMessage, updateErrorMessage]);

  useEffect(() => {
    updateFormFilledState(areFieldsFilled([passwordNew, passwordConfirmation]));
  }, [passwordConfirmation, passwordNew, updateFormFilledState]);

  return (
    <PasswordValidationContext.Provider
      value={{
        registerPasswordNew: register("passwordNew"),
        registerPasswordConfirmation: register("passwordConfirmation"),
      }}
    >
      {children}
    </PasswordValidationContext.Provider>
  );
};
