// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YqxmcsLn6u7YX_fSKOo1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.YqxmcsLn6u7YX_fSKOo1 .TzzAbWwq3RmmwY7enw64 {
  width: 100%;
  max-width: 361px;
}
.YqxmcsLn6u7YX_fSKOo1 .AWQ6WerkSIuiQ7mxriFf {
  padding: 18px 45px;
  text-align: center;
}
.YqxmcsLn6u7YX_fSKOo1 .AWQ6WerkSIuiQ7mxriFf .WUZ1IYcbDitd0GCsis1v span:not(:first-child) {
  margin-left: 8px;
}
.YqxmcsLn6u7YX_fSKOo1 .AWQ6WerkSIuiQ7mxriFf .WUZ1IYcbDitd0GCsis1v span:nth-child(3) {
  cursor: pointer;
}
.YqxmcsLn6u7YX_fSKOo1 .AWQ6WerkSIuiQ7mxriFf .UBf07e8I8kietB90zSWt {
  margin-top: 5px;
}
.YqxmcsLn6u7YX_fSKOo1 .WUSrMD3hSNEc1ULLJto5 {
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 13px 0 16px 16px;
}
.YqxmcsLn6u7YX_fSKOo1 .ixsoYR0FTMXKKGPPC2cA {
  display: flex;
  flex-direction: column;
  gap: 17px;
  justify-content: center;
  align-items: flex-start;
}
.YqxmcsLn6u7YX_fSKOo1 .mXMouDj9H56S_jgZafn3 {
  width: 72px;
  height: 72px;
  border-radius: 100%;
}

@media screen and (max-width: 500px) {
  .YqxmcsLn6u7YX_fSKOo1 {
    flex-direction: column;
  }
  .YqxmcsLn6u7YX_fSKOo1 .AWQ6WerkSIuiQ7mxriFf {
    padding: unset;
    text-align: unset;
  }
  .YqxmcsLn6u7YX_fSKOo1 .AWQ6WerkSIuiQ7mxriFf .UBf07e8I8kietB90zSWt {
    margin-top: 0;
  }
  .YqxmcsLn6u7YX_fSKOo1 .TzzAbWwq3RmmwY7enw64 {
    margin-top: 16px;
  }
  .YqxmcsLn6u7YX_fSKOo1 .mXMouDj9H56S_jgZafn3 {
    margin-top: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/ImagePicker/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;EAEA,WAAA;AAAF;AAEE;EACE,WAAA;EACA,gBAAA;AAAJ;AAGE;EACE,kBAAA;EAEA,kBAAA;AAFJ;AAKM;EACE,gBAAA;AAHR;AAKM;EACE,eAAA;AAHR;AAOI;EACE,eAAA;AALN;AASE;EACE,aAAA;EACA,QAAA;EAEA,WAAA;EACA,yBAAA;AARJ;AAWE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;EACA,uBAAA;AATJ;AAYE;EACE,WAAA;EACA,YAAA;EAEA,mBAAA;AAXJ;;AAeA;EACE;IACE,sBAAA;EAZF;EAcE;IACE,cAAA;IAEA,iBAAA;EAbJ;EAeI;IACE,aAAA;EAbN;EAiBE;IACE,gBAAA;EAfJ;EAkBE;IACE,gBAAA;EAhBJ;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n\n  width: 100%;\n\n  .imageInputWrapper {\n    width: 100%;\n    max-width: 361px;\n  }\n\n  .pickerContentWrapper {\n    padding: 18px 45px;\n\n    text-align: center;\n\n    .dragAndDropLabel {\n      span:not(:first-child) {\n        margin-left: 8px;\n      }\n      span:nth-child(3) {\n        cursor: pointer;\n      }\n    }\n\n    .imageOptionsLabel {\n      margin-top: 5px;\n    }\n  }\n\n  .mobilePickerContent {\n    display: flex;\n    gap: 8px;\n\n    width: 100%;\n    padding: 13px 0 16px 16px;\n  }\n\n  .logoPreviewWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 17px;\n    justify-content: center;\n    align-items: flex-start;\n  }\n\n  .logoPreview {\n    width: 72px;\n    height: 72px;\n\n    border-radius: 100%;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .wrapper {\n    flex-direction: column;\n\n    .pickerContentWrapper {\n      padding: unset;\n\n      text-align: unset;\n\n      .imageOptionsLabel {\n        margin-top: 0;\n      }\n    }\n\n    .imageInputWrapper {\n      margin-top: 16px;\n    }\n\n    .logoPreview {\n      margin-top: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `YqxmcsLn6u7YX_fSKOo1`,
	"imageInputWrapper": `TzzAbWwq3RmmwY7enw64`,
	"pickerContentWrapper": `AWQ6WerkSIuiQ7mxriFf`,
	"dragAndDropLabel": `WUZ1IYcbDitd0GCsis1v`,
	"imageOptionsLabel": `UBf07e8I8kietB90zSWt`,
	"mobilePickerContent": `WUSrMD3hSNEc1ULLJto5`,
	"logoPreviewWrapper": `ixsoYR0FTMXKKGPPC2cA`,
	"logoPreview": `mXMouDj9H56S_jgZafn3`
};
export default ___CSS_LOADER_EXPORT___;
