import classNames from "classnames";
import React, { type FC } from "react";

import { FormSubTitle, FormTitle } from "@components/common";

import styles from "./onboardingHeader.module.scss";

export interface Props {
  className?: string;
  subTitle: string;
  title: string;
}

export const OnboardingHeader: FC<Props> = ({ className, subTitle, title }) => (
  <div className={classNames(styles.wrapper, className)}>
    <FormTitle className={styles.title}>{title}</FormTitle>
    <FormSubTitle>{subTitle}</FormSubTitle>
  </div>
);
