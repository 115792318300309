// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fkbetpSvc46IBDKEDd96 {
  display: flex;
  column-gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Settings/Alert/AlertActions/alertActions.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,eAAA;AAAF","sourcesContent":[".wrapper {\n  display: flex;\n\n  column-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `fkbetpSvc46IBDKEDd96`
};
export default ___CSS_LOADER_EXPORT___;
