import { FilledButton, FontFamily, FontWeight, IconName, Modal, Size, TextV2, useTheme, Variant } from "@technis/ui";
import React, { type FC } from "react";
import { useTranslation } from "react-i18next";

import { translation } from "@lang/translation";

import styles from "./tutorialWelcomeModal.module.scss";
import commonStyles from "../../../../styles/tutorialRegularModal.module.scss";

export interface Props {
  isShown?: boolean;
  onClose: () => void;
  onSkip: () => void;
  onStart: () => void;
}

export const TutorialWelcomeModal: FC<Props> = ({ onClose, onSkip, onStart, isShown = false }) => {
  const { t } = useTranslation();
  const themeColors = useTheme().theme.colors;

  return (
    <Modal shown={isShown} size={Size.LARGE} contentClassName={commonStyles.modalContentWrapper} onClose={onClose}>
      <div className={commonStyles.iconWrapper}>🎉</div>
      <TextV2 className={commonStyles.title} fontFamily={FontFamily.HELVETICA} fontWeight={FontWeight.BOLD} color={themeColors.DARK_20}>
        {t(translation.tutorial.modal.welcome.titlePart1)}
        <TextV2
          className={styles.highlightedText}
          color={themeColors.PRIMARY_50}
          fontWeight={FontWeight.HEAVY}
          fontFamily={FontFamily.HELVETICA}
        >
          {t(translation.tutorial.modal.welcome.companyName)}
        </TextV2>
        {t(translation.tutorial.modal.welcome.titlePart2)}
      </TextV2>
      <TextV2 className={commonStyles.text} fontFamily={FontFamily.LATO} color={themeColors.DARK_60}>
        {t(translation.tutorial.modal.welcome.content)}
      </TextV2>

      <div className={commonStyles.actionsWrapper}>
        <FilledButton
          iconRight
          text={t(translation.tutorial.modal.welcome.skipButton)}
          colorVariant={Variant.LIGHTER}
          size={Size.LARGE}
          iconName={IconName.OUTSIDE_RIGHT}
          onClick={onSkip}
        />
        <FilledButton
          text={t(translation.tutorial.modal.welcome.startButton)}
          size={Size.LARGE}
          className={styles.startButton}
          onClick={onStart}
        />
      </div>
    </Modal>
  );
};
