// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e_fBRdiJeutfZDc5eAeR ._w5WTAWjZGOURNzUYFyz {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.e_fBRdiJeutfZDc5eAeR ._w5WTAWjZGOURNzUYFyz .qPmjfG39goRGD8o7k1A7.qPmjfG39goRGD8o7k1A7 {
  width: 100%;
}
.e_fBRdiJeutfZDc5eAeR .r8EkOMawV42nsJ07d9ZJ {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
}
.e_fBRdiJeutfZDc5eAeR .r8EkOMawV42nsJ07d9ZJ .w9jKlVuTfW83uNPh_2Ai {
  display: flex;
  gap: 8px;
  width: 100%;
}
.e_fBRdiJeutfZDc5eAeR .r8EkOMawV42nsJ07d9ZJ .w9jKlVuTfW83uNPh_2Ai div {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .e_fBRdiJeutfZDc5eAeR .w9jKlVuTfW83uNPh_2Ai {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: unset;
  }
  .e_fBRdiJeutfZDc5eAeR ._w5WTAWjZGOURNzUYFyz .i4TG1kLvmgMzPiwCQfMl {
    flex-direction: row;
    gap: 16px;
    align-items: baseline;
  }
  .e_fBRdiJeutfZDc5eAeR ._w5WTAWjZGOURNzUYFyz .qPmjfG39goRGD8o7k1A7.qPmjfG39goRGD8o7k1A7 {
    width: unset;
    font-weight: 700;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/onboardingSteps/AboutYou/styles.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAEI;EACE,WAAA;AAAN;AAIE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EAEA,gBAAA;AAHJ;AAKI;EACE,aAAA;EACA,QAAA;EAEA,WAAA;AAJN;AAKM;EACE,WAAA;AAHR;;AASA;EAEI;IACE,aAAA;IACA,sBAAA;IACA,QAAA;IACA,sBAAA;EAPJ;EAWI;IACE,mBAAA;IACA,SAAA;IACA,qBAAA;EATN;EAYI;IACE,YAAA;IAEA,gBAAA;EAXN;AACF","sourcesContent":[".wrapper {\n  .filledContentWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n\n    .filledLabel.filledLabel {\n      width: 100%;\n    }\n  }\n\n  .inputsWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n\n    margin-top: 32px;\n\n    .accountNameWrapper {\n      display: flex;\n      gap: 8px;\n\n      width: 100%;\n      div {\n        width: 100%;\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .wrapper {\n    .accountNameWrapper {\n      display: flex;\n      flex-direction: column;\n      gap: 4px;\n      justify-content: unset;\n    }\n\n    .filledContentWrapper {\n      .filledItemContainer {\n        flex-direction: row;\n        gap: 16px;\n        align-items: baseline;\n      }\n\n      .filledLabel.filledLabel {\n        width: unset;\n\n        font-weight: 700;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `e_fBRdiJeutfZDc5eAeR`,
	"filledContentWrapper": `_w5WTAWjZGOURNzUYFyz`,
	"filledLabel": `qPmjfG39goRGD8o7k1A7`,
	"inputsWrapper": `r8EkOMawV42nsJ07d9ZJ`,
	"accountNameWrapper": `w9jKlVuTfW83uNPh_2Ai`,
	"filledItemContainer": `i4TG1kLvmgMzPiwCQfMl`
};
export default ___CSS_LOADER_EXPORT___;
