import { FilledButton, FontSize, FontWeight, Size, TextV2, useTheme, Variant } from "@technis/ui";
import React, { type FC } from "react";
import { useTranslation } from "react-i18next";

import { translation } from "@lang/translation";

import styles from "./onboardingFooter.module.scss";

export interface Props {
  isLastForm?: boolean;
  isNextAvailable?: boolean;
  isPreviousAvailable?: boolean;
  isRequired?: boolean;
  loading?: boolean;
  onNextClick: () => void;
  onPreviousClick: () => void;
}

export const OnboardingFooter: FC<Props> = ({
  isLastForm,
  isRequired = false,
  isNextAvailable = false,
  isPreviousAvailable = false,
  onNextClick,
  onPreviousClick,
  loading = false,
}) => {
  const { t } = useTranslation();
  const themeColor = useTheme().theme.colors;
  const primaryButtonText = isLastForm ? t(translation.onboarding.completeOnboardingButtonText) : "Next";

  return (
    <div className={styles.wrapper}>
      <div>
        {isRequired && (
          <>
            <TextV2 color={themeColor.PRIMARY_40} fontWeight={FontWeight.SEMIBOLD} fontSize={FontSize.MD} className={styles.text}>
              *
            </TextV2>
            <TextV2 color={themeColor.BASE_40} fontWeight={FontWeight.SEMIBOLD} fontSize={FontSize.MD} className={styles.text}>
              {t(translation.onboarding.formHasRequiredFields)}
            </TextV2>
          </>
        )}
      </div>

      <div className={styles.actions}>
        <FilledButton
          size={Size.LARGE}
          colorVariant={Variant.LIGHTER}
          disabled={!isPreviousAvailable}
          text="Previous"
          onClick={onPreviousClick}
        />
        <FilledButton
          size={Size.LARGE}
          colorVariant={Variant.PRIMARY}
          disabled={!isNextAvailable}
          text={primaryButtonText}
          loading={loading}
          onClick={onNextClick}
        />
      </div>
    </div>
  );
};
