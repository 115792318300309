import { useTranslation } from "react-i18next";

import { type TabsConfig } from "@components/common/Tabs/Tabs";

import { translation } from "@lang/translation";

import { RoutePath } from "@routes/routePath";

interface Props {
  isAlertDetailsAvailable?: boolean;
  isOrganizationDataAvailable?: boolean;
  isUserManagementAvailable?: boolean;
}

export const useAvailableSettingsTabs = ({
  isAlertDetailsAvailable,
  isOrganizationDataAvailable,
  isUserManagementAvailable,
}: Props): TabsConfig[] => {
  const { t } = useTranslation();

  return [
    isOrganizationDataAvailable && {
      text: t(translation.settings.organization.tabName),
      routePath: RoutePath.SETTINGS_ORGANIZATION,
    },
    isUserManagementAvailable && {
      text: t(translation.settings.userManagement.tabName),
      routePath: RoutePath.SETTINGS_USER_MANAGEMENT,
    },
    isAlertDetailsAvailable && {
      text: t(translation.settings.alerts.tabName),
      routePath: RoutePath.SETTINGS_ALERTS,
    },
  ].filter(Boolean) as TabsConfig[];
};
