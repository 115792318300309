import React, { type FC, type ReactNode, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { BreadcrumbRouteName } from "@common/enums/breadcrumbs";
import { getFullName } from "@common/utils";

import { updateBreadcrumb } from "@redux/breadcrumbs/breadcrumbs.slice";

import { type UserData } from "@services/userService";

interface ChildrenArguments {
  updateBreadcrumbs: (user: UserData) => void;
}

interface Props {
  children?: (arguments_: ChildrenArguments) => ReactNode;
  user: UserData;
}

export const UsersManagementBreadcrumbsController: FC<Props> = ({ user, children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateBreadcrumbs = useCallback(
    (user: UserData) => {
      if (user) {
        const { firstName, lastName } = user;

        dispatch(
          updateBreadcrumb({
            text: getFullName({ firstName, lastName, t }),
            breadcrumbRouteName: BreadcrumbRouteName.SETTINGS_USER_MANAGEMENT_PROFILE,
          }),
        );
      }
    },
    [dispatch, t],
  );

  useEffect(() => {
    updateBreadcrumbs(user);
  }, [updateBreadcrumbs, user]);

  if (!children) {
    return null;
  }

  return <>{children({ updateBreadcrumbs })}</>;
};
