// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.khlVEil_3ZlAAM3dIl6z.khlVEil_3ZlAAM3dIl6z {
  padding: 0 6px;
}

.PjY978Q7ZoDSnY_R84pW.PjY978Q7ZoDSnY_R84pW {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/sidebar.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,WAAA;EACA,YAAA;AAAF","sourcesContent":[".profileDropdownOption.profileDropdownOption {\n  padding: 0 6px;\n}\n\n.profileDropdownOptionIcon.profileDropdownOptionIcon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  width: 45px;\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileDropdownOption": `khlVEil_3ZlAAM3dIl6z`,
	"profileDropdownOptionIcon": `PjY978Q7ZoDSnY_R84pW`
};
export default ___CSS_LOADER_EXPORT___;
