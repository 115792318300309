import { isPhoneValid } from "@technis/shared";
import { InputState, type PhoneChangeEventArgs, PhoneInput, Size } from "@technis/ui";
import { isEmpty } from "lodash";
import React, { type FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { removePhoneMask } from "@common/helpers/removePhoneMask";
import { useLanguage } from "@common/hooks/useLanguage";
import { type CountryPhoneData, type PhoneCountry } from "@common/interfaces";

import { FormGroup } from "@components/FormGroup";

import { translation } from "@lang/translation";

import styles from "./styles.module.scss";
import inputStyles from "../../../../styles/input.module.scss";

interface OnBoardingPhoneInputProps {
  countriesPhones: PhoneCountry[];
  countryCode: string;
  countryISO: string;
  disabled?: boolean;
  handleOnCountryChange: (countryPhone: CountryPhoneData) => void;
  isPointerEventsOff?: boolean;
  onChange: (data: PhoneChangeEventArgs) => void;
  onError?: (hasError: boolean) => void;
  size?: Size.SMALL | Size.MEDIUM | Size.LARGE;
  subText?: string;
  value: string;
}

export const OnBoardingPhoneInput: FC<OnBoardingPhoneInputProps> = ({
  size = Size.MEDIUM,
  countriesPhones,
  onChange,
  value,
  countryISO,
  handleOnCountryChange,
  subText,
  onError,
  isPointerEventsOff,
  countryCode,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const phoneValue = removePhoneMask(countryCode + value);

  const phoneInputError = useMemo(() => {
    if (!disabled && !isEmpty(value) && !isPhoneValid(phoneValue)) {
      return [t(translation.errors.invalidPhone)];
    }
    return [];
  }, [disabled, phoneValue, t, value]);

  const hasPhoneInputError = !isEmpty(phoneInputError);

  const getMaskDetails = (mask: string): { value: string; numberOfDigits: number } => {
    const regExpMaskValue = /([\d^]+)/;
    const charactersHash = /[\s()-]/g;

    const value = regExpMaskValue.exec(mask);
    const numberOfDigits = mask.replace(charactersHash, "").length;

    return {
      value: value ? value[1] : "",
      numberOfDigits,
    };
  };

  const onCountryChange = useCallback((countryPhone: PhoneCountry) => {
    handleOnCountryChange({ countryISO: countryPhone.id, countryCode: countryPhone.countryCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { countryCode: interfaceDefinedCountryCode } = useLanguage();

  const selectedOption = useMemo(() => {
    const countryPhone =
      countriesPhones.find((item) => {
        if (!countryISO && item.id === interfaceDefinedCountryCode) return true;
        return item.id === countryISO;
      }) ?? countriesPhones[0];

    if (typeof countryPhone.mask === "string") {
      return {
        ...countryPhone,
        maskDetails: [getMaskDetails(countryPhone.mask)],
      };
    }

    const maskDetails = countryPhone.mask.map((countryPhoneMask) => getMaskDetails(countryPhoneMask));

    return {
      ...countryPhone,
      maskDetails,
    };
  }, [countriesPhones, countryISO, interfaceDefinedCountryCode]);

  const options = countriesPhones.map((countryPhone) => ({
    caption: countryPhone.countryName,
    isSelected: selectedOption.countryName === countryPhone.countryName,
    onClick: () => onCountryChange(countryPhone),
  }));

  const getIsCompleted = (phone: string): boolean => selectedOption.maskDetails.some((mask) => phone.length === mask.numberOfDigits);

  useEffect(() => {
    const input = value.replace(selectedOption.countryCode, "");

    onChange({
      selectedOption,
      inputValue: value,
      isCompleted: getIsCompleted(input),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onCountryChange(selectedOption);
  }, [onCountryChange, selectedOption]);

  useEffect(() => {
    if (onError) {
      onError(hasPhoneInputError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPhoneInputError]);

  useEffect(() => {
    onCountryChange(selectedOption);
  }, [onCountryChange, selectedOption]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const targetValue = event.target.value;

    onChange({
      selectedOption,
      inputValue: targetValue,
      isCompleted: getIsCompleted(targetValue),
    });
  };

  return (
    <FormGroup errorMessages={phoneInputError}>
      <div className={styles.wrapper}>
        <PhoneInput
          className={isPointerEventsOff ? inputStyles.pointerEventsOff : ""}
          state={hasPhoneInputError ? InputState.ERROR : InputState.DEFAULT}
          isTriggerElementDisabled={isPointerEventsOff}
          subText={subText}
          size={size}
          value={value}
          placeholder={selectedOption.placeholder}
          options={options}
          inputMask={selectedOption.inputMask.replace(/\d/g, "#")}
          dropdownHeight="300px"
          disabled={disabled}
          selectedOption={
            <div className={styles.selectedCountryWrapper}>
              <img src={selectedOption.icon ?? " "} alt="Country flag image" />
              {selectedOption.countryCode}
            </div>
          }
          onChange={handleOnChange}
        />
      </div>
    </FormGroup>
  );
};
