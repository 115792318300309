import { FilledButton, FontFamily, FontWeight, IconName, Modal, Size, TextV2, useTheme, Variant } from "@technis/ui";
import React, { type FC } from "react";
import { useTranslation } from "react-i18next";

import { translation } from "@lang/translation";

import styles from "./tutorialSkipModal.module.scss";
import commonStyles from "../../../../styles/tutorialRegularModal.module.scss";

export interface Props {
  isShown?: boolean;
  onBackToTutorial: () => void;
  onClose: () => void;
  onSkip: () => void;
}

export const TutorialSkipModal: FC<Props> = ({ onBackToTutorial, onSkip, onClose, isShown = false }) => {
  const { t } = useTranslation();
  const themeColors = useTheme().theme.colors;

  return (
    <Modal isCloseButtonHidden shown={isShown} size={Size.MEDIUM} className={styles.modalWrapper} onClose={onClose}>
      <TextV2 className={commonStyles.title} fontFamily={FontFamily.HELVETICA} fontWeight={FontWeight.BOLD} color={themeColors.DARK_20}>
        {t(translation.tutorial.modal.confirmSkipping.title)}
      </TextV2>
      <TextV2 className={commonStyles.text} fontFamily={FontFamily.LATO} color={themeColors.DARK_60}>
        {t(translation.tutorial.modal.confirmSkipping.content)}
      </TextV2>

      <div className={commonStyles.actionsWrapper}>
        <FilledButton
          text={t(translation.tutorial.modal.confirmSkipping.backToTutorialButton)}
          size={Size.LARGE}
          onClick={onBackToTutorial}
        />
        <FilledButton
          iconRight
          text={t(translation.tutorial.modal.confirmSkipping.skipButton)}
          colorVariant={Variant.LIGHTER}
          size={Size.LARGE}
          iconName={IconName.OUTSIDE_RIGHT}
          onClick={onSkip}
        />
      </div>
    </Modal>
  );
};
