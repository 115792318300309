import { SectionTitle, SubTitle } from "@technis/ui";
import classnames from "classnames";
import React, { type FC, type ReactNode } from "react";

import styles from "../../../styles/serviceModalHeader.module.scss";
import LockIcon from "../../assets/colorfulIcons/lock.svg";

export interface Props {
  className?: string;
  icon?: JSX.Element;
  subTitle?: ReactNode;
  title: ReactNode;
}

export const ServiceModalHeader: FC<Props> = ({ title, subTitle, className, icon }) => (
  <div className={classnames(styles.wrapper, className)}>
    {icon ?? <LockIcon className={styles.logo} />}
    <SectionTitle className={styles.title}>{title}</SectionTitle>
    {subTitle && <SubTitle className={styles.subTitle}>{subTitle}</SubTitle>}
  </div>
);
