import {
  FontFamily,
  FontSize,
  FontWeight,
  IconCommon,
  IconName,
  Size,
  TextV2,
  TooltipPosition_V2,
  TooltipV2,
  useTheme,
  Variant,
} from "@technis/ui";
import classNames from "classnames";
import React, { type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { type SecurityStatus } from "@common/enums";

import { SecurityLabel } from "@components/common/Form/SecurityLabel/SecurityLabel";
import { getMultilineHTML } from "@components/common/helpers/getMultilineHTML";

import { translation } from "@lang/translation";

import styles from "./formItem.module.scss";

export enum Necessities {
  NONE = "NONE",
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

export interface Props {
  children?: ReactNode;
  className?: string;
  isNoChildrenPadding?: boolean;
  isOptional?: boolean;
  isRequired?: boolean;
  necessities?: Necessities;
  securityLabelStatus?: SecurityStatus;
  subTitle?: ReactNode;
  title: ReactNode;
  tooltipContent?: string;
}

export const FormItem: FC<Props> = ({
  tooltipContent,
  className,
  isNoChildrenPadding = false,
  children,
  title,
  subTitle,
  securityLabelStatus,
  necessities = Necessities.NONE,
}) => {
  const { t } = useTranslation();
  const themeColor = useTheme().theme.colors;
  const isOptional = necessities === Necessities.OPTIONAL;
  const isRequired = necessities === Necessities.REQUIRED;

  return (
    <div className={classNames(styles.container, className)}>
      <label className={styles.label}>
        <TextV2
          className={styles.title}
          fontSize={FontSize.MD}
          color={themeColor.DARK_30}
          fontFamily={FontFamily.HELVETICA}
          fontWeight={FontWeight.BOLD}
        >
          {title}
          {securityLabelStatus && <SecurityLabel securityStatus={securityLabelStatus} />}
          {isRequired && (
            <TextV2 className={styles.decoration} color={themeColor.PRIMARY_40}>
              *
            </TextV2>
          )}
          {isOptional && (
            <TextV2 className={styles.decoration} fontWeight={FontWeight.REGULAR}>
              {t(translation.onboarding.optionalLabel)}
            </TextV2>
          )}
          {tooltipContent && (
            <span className={styles.tooltip}>
              <TooltipV2
                contentElement={getMultilineHTML(tooltipContent)}
                position={TooltipPosition_V2.TOP}
                colorVariant={Variant.SECONDARY}
              >
                <IconCommon name={IconName.CIRCLE_QUESTION_MARK} size={Size.MEDIUM} color={themeColor.PRIMARY_50} />
              </TooltipV2>
            </span>
          )}
        </TextV2>

        {subTitle && (
          <TextV2 className={styles.subTitle} color={themeColor.DARK_60} fontSize={FontSize.SM}>
            {subTitle}
          </TextV2>
        )}
      </label>

      <label className={styles.labelMobile}>
        <TextV2 className={styles.title} fontSize={FontSize.SM} color={themeColor.BASE_30} fontWeight={FontWeight.MEDIUM}>
          {title}
          {isRequired && (
            <TextV2 color={themeColor.PRIMARY_40} fontWeight={FontWeight.BOLD} fontSize={FontSize.MD} className={styles.decoration}>
              *
            </TextV2>
          )}
          {isOptional && (
            <TextV2 className={styles.decoration} color={themeColor.BASE_40} fontWeight={FontWeight.SEMIBOLD} fontSize={FontSize.SM}>
              {t(translation.onboarding.optionalLabel)}
            </TextV2>
          )}
        </TextV2>
      </label>

      <div
        className={classNames(styles.childrenWrapper, {
          [styles.childrenPadding]: !isNoChildrenPadding,
        })}
      >
        {children}
      </div>
    </div>
  );
};
