import { IconCommon, IconName, Size } from "@technis/ui";
import classNames from "classnames";
import { type TFunction } from "i18next";
import React, { type FC } from "react";
import { useTranslation } from "react-i18next";

import { SecurityStatus } from "@common/enums";

import { translation } from "@lang/translation";

import styles from "./styles.module.scss";

type SecurityLabelConfigType = {
  [key in SecurityStatus]: {
    className: string;
    iconName: IconName;
    text: string;
  };
};

const getSecurityLabelConfig = (t: TFunction<"translation", undefined, "translation">): SecurityLabelConfigType => ({
  [SecurityStatus.TWO_FA]: {
    className: styles.twoFA,
    iconName: IconName.SECURITY,
    text: t(translation.userProfile.securityLabel.twoFA),
  },
  [SecurityStatus.VERIFIED]: {
    className: styles.verified,
    iconName: IconName.CIRCLE_CHECK_ICON,
    text: t(translation.userProfile.securityLabel.verified),
  },
  [SecurityStatus.NOT_VERIFIED]: {
    className: styles.notVerified,
    iconName: IconName.CIRCLE_EXCLAMATION_ICON,
    text: t(translation.userProfile.securityLabel.notVerified),
  },
});

interface SecurityLabelProps {
  securityStatus: SecurityStatus;
}

export const SecurityLabel: FC<SecurityLabelProps> = ({ securityStatus }) => {
  const { t } = useTranslation();
  const { className, iconName, text } = getSecurityLabelConfig(t)[securityStatus];

  return (
    <div className={classNames(styles.wrapper, className)}>
      <IconCommon name={iconName} size={Size.SMALL} />
      <div>{text}</div>
    </div>
  );
};
