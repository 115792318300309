// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mA1CnyWrYdxDG1TAq_9w {
  display: flex;
  margin-bottom: 24px;
  column-gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Tabs/tabs.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,mBAAA;EAEA,gBAAA;AADF","sourcesContent":[".tabsWrapper {\n  display: flex;\n\n  margin-bottom: 24px;\n\n  column-gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsWrapper": `mA1CnyWrYdxDG1TAq_9w`
};
export default ___CSS_LOADER_EXPORT___;
