// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itf8AyqU8jobL5rE7qLA {
  opacity: 0.4;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/OnboardingDropdownWrapper/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,oBAAA;AAAF","sourcesContent":[".disabled {\n  opacity: 0.4;\n\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabled": `itf8AyqU8jobL5rE7qLA`
};
export default ___CSS_LOADER_EXPORT___;
