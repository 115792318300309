import React, { type ReactNode, type FC } from "react";
import { useTranslation } from "react-i18next";

import {
  type GetUserRouteName,
  UsersManagementParameters,
} from "@components/Settings/UsersManagement/UsersManagementParameters/UsersManagementParameters";
import { Tabs, type TabsConfig } from "@components/common/Tabs/Tabs";

import { translation } from "@lang/translation";

import { RoutePath } from "@routes/routePath";

export const UserManagementProfileTabs: FC = () => {
  const { t } = useTranslation();

  const getTabsConfig = (getUserRouteName: GetUserRouteName): TabsConfig[] =>
    [
      {
        text: t(translation.userProfile.general.tabName),
        routePath: RoutePath.SETTINGS_USER_MANAGEMENT_GENERAL,
      },
      {
        text: t(translation.userProfile.preferences.tabName),
        routePath: RoutePath.SETTINGS_USER_MANAGEMENT_PREFERENCES,
      },
      {
        text: t(translation.userProfile.security.tabName),
        routePath: RoutePath.SETTINGS_USER_MANAGEMENT_SECURITY,
      },
    ].map((tab) => ({
      ...tab,
      routePath: getUserRouteName(tab.routePath),
    }));

  return (
    <UsersManagementParameters>
      {({ getUserRouteName }): ReactNode => <Tabs tabs={getTabsConfig(getUserRouteName)} />}
    </UsersManagementParameters>
  );
};
