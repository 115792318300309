import { ToastMessages, withTheme } from "@technis/ui";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeToast } from "@redux/toast/toast.slice";

import { type RootState } from "@store/rootReducer";

export const ToastHandlerComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const toasts = useSelector((state: RootState) => state.toast.toasts) || [];

  const onClose = (toastId: string): void => {
    dispatch(closeToast(toastId));
  };

  return <ToastMessages messages={toasts} onClose={onClose} />;
};

export const ToastHandler = withTheme(ToastHandlerComponent);
