// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kfD41VXZbwV7Kh8jiweT {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
}
.kfD41VXZbwV7Kh8jiweT .Pk26y5cBOZQIV3hPteZS {
  line-height: 170%;
}
.kfD41VXZbwV7Kh8jiweT .Ia5q4VYDNnjJ1bG7tsBH {
  align-self: end;
}

@media screen and (min-width: 500px) {
  .kfD41VXZbwV7Kh8jiweT {
    flex-direction: row;
    row-gap: 0;
    justify-content: space-between;
    align-items: center;
  }
  .kfD41VXZbwV7Kh8jiweT .Ia5q4VYDNnjJ1bG7tsBH {
    display: flex;
    column-gap: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/OnboardingFooter/onboardingFooter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EAEA,WAAA;AAAF;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,eAAA;AADJ;;AAKA;EACE;IACE,mBAAA;IACA,UAAA;IACA,8BAAA;IACA,mBAAA;EAFF;EAIE;IACE,aAAA;IAEA,gBAAA;EAHJ;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  row-gap: 24px;\n\n  width: 100%;\n\n  .text {\n    line-height: 170%;\n  }\n\n  .actions {\n    align-self: end;\n  }\n}\n\n@media screen and (min-width: 500px) {\n  .wrapper {\n    flex-direction: row;\n    row-gap: 0;\n    justify-content: space-between;\n    align-items: center;\n\n    .actions {\n      display: flex;\n\n      column-gap: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `kfD41VXZbwV7Kh8jiweT`,
	"text": `Pk26y5cBOZQIV3hPteZS`,
	"actions": `Ia5q4VYDNnjJ1bG7tsBH`
};
export default ___CSS_LOADER_EXPORT___;
