import { ScrollableArea, type ScrollableAreaProps } from "@technis/ui";
import React, { type FC, type ReactNode } from "react";

import styles from "../../../styles/page.module.scss";

interface Props extends ScrollableAreaProps {
  children: ReactNode;
}

export const PageScrollableArea: FC<Props> = ({ children, ...rest }) => (
  <ScrollableArea {...rest} className={styles.pageContainer}>
    {children}
  </ScrollableArea>
);
