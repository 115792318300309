import { type PayloadAction } from "@reduxjs/toolkit";
import {
  type DropzoneRef,
  FilePicker,
  FilledButton,
  FontSize,
  FontWeight,
  IconName,
  Size,
  TextV2,
  type ToastMessageV2,
  ToastType,
  useTheme,
  Variant,
} from "@technis/ui";
import React, { type FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { decodeToBase64 } from "@common/helpers";
import { useDesktopResolution } from "@common/hooks";

import { translation } from "@lang/translation";

import { displayToast } from "@redux/toast/toast.slice";

import styles from "./styles.module.scss";

interface ImagePickerProps {
  imageSrc: string;
  onImagePickerChange: (imageSource: string) => void;
}

export const ImagePicker: FC<ImagePickerProps> = ({ onImagePickerChange, imageSrc }) => {
  const { t } = useTranslation();

  const defaultImagePickerCaptions = {
    dragAndDropLabel: t(translation.imagePicker.dragAndDropLabel),
    dividerLabel: t(translation.imagePicker.dividerLabel),
    replaceLabel: t(translation.imagePicker.replaceLabel),
    dimensionLabel: t(translation.imagePicker.dimensionLabel),
    sizeLabel: t(translation.imagePicker.sizeLabel),
    removeButtonText: t(translation.imagePicker.removeButtonText),
    changeButtonText: t(translation.imagePicker.changeButtonText),
    imageSizeError: t(translation.imagePicker.imageSizeError),
  };

  const dispatch = useDispatch();

  const theme = useTheme().theme;

  const { isDesktopResolution } = useDesktopResolution();

  const filePickerReference = useRef<DropzoneRef | null>(null);

  const handleSetReference = (reference: DropzoneRef): void => {
    filePickerReference.current = reference;
  };

  const { dragAndDropLabel, dimensionLabel, dividerLabel, replaceLabel, sizeLabel, imageSizeError, removeButtonText, changeButtonText } =
    defaultImagePickerCaptions;

  const handleChangeButtonClick = (): void => {
    if (filePickerReference.current) {
      filePickerReference.current.open();
    }
  };

  const handleImagePickerChange = async (files: File[]): Promise<PayloadAction<Omit<ToastMessageV2, "id">> | undefined> => {
    const image = files[0];
    const imageSizeInMb = image.size / 1024 ** 2;
    if (imageSizeInMb > 1) {
      return dispatch(
        displayToast({
          text: imageSizeError,
          type: ToastType.ERROR,
        }),
      );
    }
    const base64Image = (await decodeToBase64(image)) as string;
    onImagePickerChange(base64Image);
  };

  const handleRemoveButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    onImagePickerChange("");
  };

  const OrganizationLogoPreview = (): JSX.Element => <img src={imageSrc} className={styles.logoPreview} alt="Organization logo preview" />;

  const RemoveButton = (): JSX.Element => (
    <FilledButton
      iconName={IconName.TRASH}
      colorVariant={Variant.SECONDARY}
      size={Size.LARGE}
      text={removeButtonText}
      onClick={handleRemoveButtonClick}
    />
  );

  const FilePickerContent = (): JSX.Element => (
    <div className={styles.pickerContentWrapper}>
      {isDesktopResolution && (
        <div className={styles.dragAndDropLabel}>
          <TextV2 color={theme.colors.OLD_LIGHT_10} fontWeight={FontWeight.SEMIBOLD}>
            {dragAndDropLabel}
          </TextV2>
          <TextV2 color={theme.colors.OLD_LIGHT_20}>{dividerLabel}</TextV2>
          <TextV2 color={theme.colors.PRIMARY_40} fontWeight={FontWeight.SEMIBOLD}>
            {replaceLabel}
          </TextV2>
        </div>
      )}
      <div className={styles.imageOptionsLabel}>
        <TextV2
          fontWeight={FontWeight.MEDIUM}
          color={isDesktopResolution ? theme.colors.OLD_LIGHT_20 : theme.colors.BASE_40}
          fontSize={FontSize.XS}
        >
          {dimensionLabel}
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <TextV2 fontWeight={FontWeight.MEDIUM} color={theme.colors.OLD_BASE_30}>
            240px x 240px
          </TextV2>
          <br /> {sizeLabel}
        </TextV2>
      </div>
    </div>
  );

  const DesktopComponent = (): JSX.Element => (
    <>
      {imageSrc && (
        <div className={styles.logoPreviewWrapper}>
          <OrganizationLogoPreview />
          <RemoveButton />
        </div>
      )}
      <div className={styles.imageInputWrapper}>
        <FilePicker onChange={handleImagePickerChange}>
          <FilePickerContent />
        </FilePicker>
      </div>
    </>
  );

  const MobileComponent = (): JSX.Element => (
    <>
      <FilePickerContent />
      <div className={styles.imageInputWrapper}>
        <FilePicker
          noClick
          noDrag
          backgroundColor={theme.colors.BASE_80}
          setFilePickerRef={handleSetReference}
          icon={imageSrc ? <OrganizationLogoPreview /> : undefined}
          onChange={handleImagePickerChange}
        >
          {
            <div className={styles.mobilePickerContent}>
              {imageSrc && <RemoveButton />}
              <FilledButton
                iconName={IconName.REFRESH}
                colorVariant={Variant.LIGHT}
                size={Size.LARGE}
                text={changeButtonText}
                onClick={handleChangeButtonClick}
              />
            </div>
          }
        </FilePicker>
      </div>
    </>
  );

  return <div className={styles.wrapper}>{isDesktopResolution ? <DesktopComponent /> : <MobileComponent />}</div>;
};
