// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VHsL9RxOr6yl7GrMPxYn {
  width: 100%;
}
.VHsL9RxOr6yl7GrMPxYn .WTVZb5GQK64anZRYgapV {
  display: flex;
  gap: 8px;
  align-items: center;
}
.VHsL9RxOr6yl7GrMPxYn .WTVZb5GQK64anZRYgapV img {
  width: 20px;
  height: 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/OnBoardingPhoneInput/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN","sourcesContent":[".wrapper {\n  width: 100%;\n\n  .selectedCountryWrapper {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n\n    img {\n      width: 20px;\n      height: 13px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `VHsL9RxOr6yl7GrMPxYn`,
	"selectedCountryWrapper": `WTVZb5GQK64anZRYgapV`
};
export default ___CSS_LOADER_EXPORT___;
