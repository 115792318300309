import { Loader } from "@technis/ui";
import React, { type FC, type PropsWithChildren } from "react";

interface Props {
  loading?: boolean;
}
export const PageLoader: FC<PropsWithChildren<Props>> = ({ loading = false, children }) =>
  !children || (children && loading) ? (
    <div className="page-loader">
      <Loader />
    </div>
  ) : (
    <>{children}</>
  );
