import React, { type FC, type ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { UsersManagementParameters } from "@components/Settings/UsersManagement/UsersManagementParameters/UsersManagementParameters";

import { RoutePath } from "@routes/routePath";

export const UserManagementDefaultPage: FC = () => (
  <UsersManagementParameters>
    {({ getUserRouteName }): ReactNode => <Navigate replace to={getUserRouteName(RoutePath.SETTINGS_USER_MANAGEMENT_GENERAL)} />}
  </UsersManagementParameters>
);
