// noinspection GraphQLUnresolvedReference

import { gql } from "@apollo/client";

export const VERIFY_INVITATION_CONTACT = gql`
  mutation VerifyInvitationContact($token: String!, $otp: String!) {
    verifyInvitationContact(token: $token, otp: $otp) {
      id
    }
  }
`;
