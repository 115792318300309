import { DefaultInput, type PhoneChangeEventArgs, Size, TextV2, useTheme } from "@technis/ui";
import React, { type ChangeEvent, type FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { countriesPhones } from "@common/configs/onboarding/countriesPhones";
import { areFieldsFilled } from "@common/helpers";
import { type CountryPhoneData } from "@common/interfaces";

import { FormItem, Necessities } from "@components/common/Form/FormItem/FormItem";
import { ImagePicker } from "@components/onboarding/ImagePicker/ImagePicker";
import { OnBoardingPhoneInput } from "@components/onboarding/OnBoardingPhoneInput/OnBoardingPhoneInput";
import { OnboardingContext } from "@components/onboarding/Onboarding/Onboarding";

import { translation } from "@lang/translation";

import styles from "./styles.module.scss";
import inputStyles from "../../../../../styles/input.module.scss";

export const AboutYou: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme().theme;

  const aboutYouFormCaption = {
    organizationNameTitle: t(translation.aboutYouForm.organizationName),
    userEmail: t(translation.aboutYouForm.userEmail),
    accountNameItemTitle: t(translation.aboutYouForm.accountNameItemTitle),
    accountNameItemDescription: t(translation.aboutYouForm.accountNameItemDescription),
    phoneItemTitle: t(translation.aboutYouForm.phoneItemTitle),
    phoneItemDescription: t(translation.aboutYouForm.phoneItemDescription),
    photoItemTitle: t(translation.aboutYouForm.photoItemTitle),
    photoItemDescription: t(translation.aboutYouForm.photoItemDescription),
    firstNamePlaceholder: t(translation.aboutYouForm.firstNamePlaceholder),
    lastNamePlaceholder: t(translation.aboutYouForm.lastNamePlaceholder),
  };

  const {
    organizationNameTitle,
    accountNameItemDescription,
    phoneItemDescription,
    photoItemDescription,
    phoneItemTitle,
    photoItemTitle,
    accountNameItemTitle,
    userEmail,
    firstNamePlaceholder,
    lastNamePlaceholder,
  } = aboutYouFormCaption;

  const {
    updateData,
    updateFormFilledState,
    countryCode,
    countryISO,
    organizationName,
    email,
    phoneNumber,
    firstName,
    lastName,
    userImage,
  } = useContext(OnboardingContext);

  useEffect(() => {
    updateFormFilledState(areFieldsFilled([firstName, lastName]));
  }, [firstName, lastName, updateFormFilledState]);

  const handleOnImagePickerChange = async (imageSource: string): Promise<void> => {
    updateData({ propName: "userImage", value: imageSource });
  };

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateData({ propName: "firstName", value: event.target.value });
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateData({ propName: "lastName", value: event.target.value });
  };

  const handleOnCountryChange = ({ countryISO }: CountryPhoneData): void => {
    updateData({
      propName: "countryISO",
      value: countryISO,
    });
  };

  const handlePhoneChange = ({ inputValue }: PhoneChangeEventArgs): void => {
    updateData({
      propName: "phoneNumber",
      value: inputValue,
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.filledContentWrapper}>
        <FormItem className={styles.filledItemContainer} title={organizationNameTitle}>
          <TextV2 color={theme.colors.BASE_30} className={styles.filledLabel}>
            {organizationName}
          </TextV2>
        </FormItem>
        <FormItem className={styles.filledItemContainer} title={userEmail}>
          <TextV2 color={theme.colors.BASE_30} className={styles.filledLabel}>
            {email}
          </TextV2>
        </FormItem>
      </div>
      <div className={styles.inputsWrapper}>
        <FormItem title={accountNameItemTitle} subTitle={accountNameItemDescription} necessities={Necessities.REQUIRED}>
          <div className={styles.accountNameWrapper}>
            <DefaultInput
              className={inputStyles.input}
              size={Size.LARGE}
              placeholder={firstNamePlaceholder}
              value={firstName}
              onChange={handleFirstNameChange}
            />
            <DefaultInput
              className={inputStyles.input}
              size={Size.LARGE}
              placeholder={lastNamePlaceholder}
              value={lastName}
              onChange={handleLastNameChange}
            />
          </div>
        </FormItem>
        <FormItem title={phoneItemTitle} subTitle={phoneItemDescription} necessities={Necessities.OPTIONAL}>
          <OnBoardingPhoneInput
            size={Size.LARGE}
            countriesPhones={countriesPhones}
            value={phoneNumber}
            countryISO={countryISO}
            handleOnCountryChange={handleOnCountryChange}
            countryCode={countryCode}
            onChange={handlePhoneChange}
          />
        </FormItem>
        <FormItem title={photoItemTitle} subTitle={photoItemDescription} necessities={Necessities.OPTIONAL}>
          <ImagePicker imageSrc={userImage} onImagePickerChange={handleOnImagePickerChange} />
        </FormItem>
      </div>
    </div>
  );
};
