import React, { type FC, type PropsWithChildren } from "react";

import { UserManagementDataWrapper } from "@components/Settings/UsersManagement/UserManagementDataWrapper/UserManagementDataWrapper";
import { UserManagementProfileTabs } from "@components/Settings/UsersManagement/UserManagementProfileTabs/UserManagementProfileTabs";
import { UserProfileWrapper } from "@components/UserProfile/UserProfileWrapper/UserProfileWrapper";

export const UsersManagementWrapper: FC<PropsWithChildren> = ({ children }) => (
  <UserProfileWrapper HeaderElement={<UserManagementProfileTabs />}>
    <UserManagementDataWrapper>{children}</UserManagementDataWrapper>
  </UserProfileWrapper>
);
