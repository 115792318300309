import { useQuery } from "@apollo/client";
import React, { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";

import { PageLoader } from "@components/PageLoader";

import { translation } from "@lang/translation";

import { displayToastError } from "@redux/toast/toast.slice";

import { RoutePath } from "@routes/routePath";

import { SHORT_LINK_BY_CODE_QUERY, type ShortLinkByCodeResult } from "@services/shortLinkService";

export const ShortLink: FC = () => {
  const { t } = useTranslation();
  const { code = "" } = useParams();
  const dispatch = useDispatch();

  const { error, data } = useQuery<ShortLinkByCodeResult>(SHORT_LINK_BY_CODE_QUERY, {
    variables: { code },
    skip: !code,
  });

  const shortLinkByCode = data?.shortLinkByCode;

  if (shortLinkByCode) {
    window.location.replace(shortLinkByCode);
    return null;
  }

  if (error) {
    dispatch(displayToastError(t(translation.errors.notFound)));
    return <Navigate replace to={RoutePath.NOT_FOUND} />;
  }

  return <PageLoader />;
};
