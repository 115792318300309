import React, { type FC } from "react";
import { useTranslation } from "react-i18next";

import { Tabs, type TabsConfig } from "@components/common/Tabs/Tabs";

import { translation } from "@lang/translation";

import { RoutePath } from "@routes/routePath";

export const UserProfileTabs: FC = () => {
  const { t } = useTranslation();

  const tabs: TabsConfig[] = [
    {
      text: t(translation.userProfile.general.tabName),
      routePath: RoutePath.USER_PROFILE_GENERAL,
    },
    {
      text: t(translation.userProfile.preferences.tabName),
      routePath: RoutePath.USER_PROFILE_PREFERENCES,
    },
    {
      text: t(translation.userProfile.security.tabName),
      routePath: RoutePath.USER_PROFILE_SECURITY,
    },
  ];

  return <Tabs tabs={tabs} />;
};
