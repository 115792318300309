import { FilledButton, FlatButton, FontFamily, FontSize, FontWeight, Modal, Size, TextV2, useTheme, Variant } from "@technis/ui";
import classNames from "classnames";
import React, { type ReactNode, type FC } from "react";
import { useTranslation } from "react-i18next";

import { translation } from "@lang/translation";

import styles from "./styles.module.scss";
import commonStyles from "../../../styles/tutorialRegularModal.module.scss";

interface RegularModalProps {
  approveButtonColorVariant?: Variant.PRIMARY | Variant.SECONDARY;
  approveButtonText: string;
  content: ReactNode;
  isShown?: boolean;
  onApproveButtonClick: () => void;
  onCancelClick: () => void;
  onDiscardButtonClick?: () => void;
  size?: Size.SMALL | Size.MEDIUM;
  title: string;
}

const BUTTON_SIZE = Size.LARGE;

export const RegularModal: FC<RegularModalProps> = ({
  isShown,
  onCancelClick,
  onApproveButtonClick,
  approveButtonText,
  onDiscardButtonClick,
  title,
  content,
  size = Size.MEDIUM,
  approveButtonColorVariant = Variant.PRIMARY,
}) => {
  const { t } = useTranslation();
  const {
    theme: { colors: themeColors },
  } = useTheme();

  return (
    <Modal shown={isShown} size={size} onClose={onCancelClick}>
      <TextV2
        fontSize={FontSize.XL}
        className={classNames(commonStyles.title, styles.textNotCentered)}
        fontFamily={FontFamily.HELVETICA}
        fontWeight={FontWeight.BOLD}
        color={themeColors.DARK_20}
      >
        {title}
      </TextV2>
      <div className={classNames(commonStyles.text, styles.textNotCentered)}>{content}</div>

      <div className={commonStyles.actionsWrapper}>
        <FilledButton
          className={styles.button}
          text={t(translation.actions.cancel)}
          colorVariant={Variant.LIGHTER}
          size={BUTTON_SIZE}
          onClick={onCancelClick}
        />
        <div className={styles.approveButtons}>
          {onDiscardButtonClick && <FlatButton text={t(translation.actions.discard)} size={BUTTON_SIZE} onClick={onDiscardButtonClick} />}
          <FilledButton
            iconRight
            colorVariant={approveButtonColorVariant}
            className={styles.button}
            text={approveButtonText}
            size={BUTTON_SIZE}
            onClick={onApproveButtonClick}
          />
        </div>
      </div>
    </Modal>
  );
};
