// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TQSDC15ZxbkJsDgR0sjV {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.KZ0GIGJpQHrqJWCSZO8u {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 40px 24px 24px;
}

.tk5ow0YcqLMYoXORsHo5 {
  flex: 1;
}

@media screen and (min-width: 500px) {
  .KZ0GIGJpQHrqJWCSZO8u {
    padding: 65px 120px 48px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/Onboarding/onboarding.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EAEA,iBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EAEA,uBAAA;AADF;;AAIA;EACE,OAAA;AADF;;AAIA;EACE;IACE,wBAAA;EADF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n\n  min-height: 100vh;\n}\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n\n  padding: 40px 24px 24px;\n}\n\n.content {\n  flex: 1;\n}\n\n@media screen and (min-width: 500px) {\n  .wrapper {\n    padding: 65px 120px 48px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TQSDC15ZxbkJsDgR0sjV`,
	"wrapper": `KZ0GIGJpQHrqJWCSZO8u`,
	"content": `tk5ow0YcqLMYoXORsHo5`
};
export default ___CSS_LOADER_EXPORT___;
