// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fbbKleBYCmcdVuHVtbPw {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.fbbKleBYCmcdVuHVtbPw .vgu8aEgfuj0p20eL2TWw {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/onboardingSteps/OrganizationSettings/styles.modules.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,WAAA;AACJ","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n\n  .inputWrapper {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `fbbKleBYCmcdVuHVtbPw`,
	"inputWrapper": `vgu8aEgfuj0p20eL2TWw`
};
export default ___CSS_LOADER_EXPORT___;
