import { SectionTitle, useTheme } from "@technis/ui";
import classNames from "classnames";
import React, { type FC, type ReactNode } from "react";

import styles from "./formTitle.module.scss";

export interface Props {
  children?: ReactNode;
  className?: string;
}

export const FormTitle: FC<Props> = ({ className, children }) => {
  const themeColor = useTheme().theme.colors;

  return (
    <SectionTitle color={themeColor.BASE_20} className={classNames(styles.title, styles.form, className)}>
      {children}
    </SectionTitle>
  );
};
