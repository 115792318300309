// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MSTL6RtM_IVbmg3qJwfh .YA8K8INScWQ4Vs5xZNUA {
  padding-left: 4px;
}
.MSTL6RtM_IVbmg3qJwfh .saNcUh4pZnStzBR9ones {
  margin-bottom: 16px;
}
.MSTL6RtM_IVbmg3qJwfh .yIR5PC44HQHTjoy7fhrh {
  display: block;
  margin-bottom: 16px;
}
.MSTL6RtM_IVbmg3qJwfh .pSGEh0lnKvfuIvCI0mPJ {
  display: flex;
  align-items: center;
}
.MSTL6RtM_IVbmg3qJwfh .XRDnrrgkEccDpwfDl3y6 {
  margin-right: 12px;
}
.MSTL6RtM_IVbmg3qJwfh .bBKJbZBArxV7wHCsaxm0 {
  display: flex;
  flex-direction: column;
  row-gap: 33px;
}

.pRnjagsvPtjzUHFvj4cL {
  text-transform: capitalize;
}

._HmPCHuYV79QsvY96g51 {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding/onboardingSteps/Security/security.module.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,cAAA;EAEA,mBAAA;AAHJ;AAME;EACE,aAAA;EACA,mBAAA;AAJJ;AAOE;EACE,kBAAA;AALJ;AAQE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AANJ;;AAUA;EACE,0BAAA;AAPF;;AAUA;EACE,0BAAA;AAPF","sourcesContent":[".wrapper {\n  .decoration {\n    padding-left: 4px;\n  }\n\n  .extraFactorCheck {\n    margin-bottom: 16px;\n  }\n\n  .subTitle {\n    display: block;\n\n    margin-bottom: 16px;\n  }\n\n  .emailWrapper {\n    display: flex;\n    align-items: center;\n  }\n\n  .emailIcon {\n    margin-right: 12px;\n  }\n\n  .emailOption {\n    display: flex;\n    flex-direction: column;\n    row-gap: 33px;\n  }\n}\n\n.listItem {\n  text-transform: capitalize;\n}\n\n.dropdownInput {\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MSTL6RtM_IVbmg3qJwfh`,
	"decoration": `YA8K8INScWQ4Vs5xZNUA`,
	"extraFactorCheck": `saNcUh4pZnStzBR9ones`,
	"subTitle": `yIR5PC44HQHTjoy7fhrh`,
	"emailWrapper": `pSGEh0lnKvfuIvCI0mPJ`,
	"emailIcon": `XRDnrrgkEccDpwfDl3y6`,
	"emailOption": `bBKJbZBArxV7wHCsaxm0`,
	"listItem": `pRnjagsvPtjzUHFvj4cL`,
	"dropdownInput": `_HmPCHuYV79QsvY96g51`
};
export default ___CSS_LOADER_EXPORT___;
