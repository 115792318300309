import { NumericStep, NumericStepBar, ProgressBarV2 } from "@technis/ui";
import classNames from "classnames";
import React, { type FC } from "react";

import styles from "./stepBar.module.scss";
import Logo from "../../../assets/logo/logo.svg";
import SmLogo from "../../../assets/logo/sm-logo.svg";

export interface Props {
  className?: string;
  currentStep: number;
  isStepDisabled?: (step: number) => boolean;
  onStepClick: (step: number) => void;
  steps: string[];
}

export const StepBar: FC<Props> = ({ className, currentStep, steps, onStepClick, isStepDisabled }) => {
  const shownStepNumber = currentStep + 1;
  const progress = (shownStepNumber * 100) / steps.length;

  return (
    <>
      <div className={classNames(styles.bar, className)}>
        <Logo className={styles.logo} />
        <NumericStepBar currentStep={shownStepNumber} steps={steps} isStepDisabled={isStepDisabled} onStepClick={onStepClick} />
      </div>

      <div className={classNames(styles.barMobile, className)}>
        <SmLogo className={styles.logo} />
        <NumericStep disabled currentStep={shownStepNumber} step={shownStepNumber} label={steps[currentStep]} />
      </div>

      <ProgressBarV2 progress={progress} />
    </>
  );
};
