import { type FetchResult, useMutation } from "@apollo/client";
import { OtpContactType } from "@technis/shared";
import { ToastType } from "@technis/ui";
import React, { createContext, type FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { type UpdateUserDataArguments, type UserData } from "@common/interfaces";

import { StepBar } from "@components/common/StepBar/StepBar";
import { OnboardingFooter } from "@components/onboarding/OnboardingFooter";
import { OnboardingHeader } from "@components/onboarding/OnboardingHeader";
import { PhoneVerification } from "@components/onboarding/onboardingSteps/PhoneVerification/PhoneVerification";

import { translation } from "@lang/translation";

import { updateConfirmButtonState, updateUserData, updateValidationState } from "@redux/onboarding/onboardingUser.slice";
import { displayToast, displayToastError } from "@redux/toast/toast.slice";

import { RoutePath } from "@routes/routePath";

import {
  ACCEPT_INVITATION_2FA_EMAIL_MUTATION,
  ACCEPT_INVITATION_MUTATION,
  type AcceptInvitation2FaEmailResult,
  type AcceptInvitationResult,
  SEND_INVITATION_VERIFICATION_CODE_MUTATION,
  type SendInvitationVerificationCodeResult,
} from "@services/invitationService";

import { type RootState } from "@store/rootReducer";

import styles from "./onboarding.module.scss";

interface OnboardingContextArguments extends UserData {
  isDefaultTimezone: boolean;
  setIsDefaultTimezone: (isDefault: boolean) => void;
  updateData: (data: UpdateUserDataArguments) => void;
  updateErrorMessage: (message: string | undefined) => void;
  updateFormFilledState: (isFilled: boolean) => void;
}

export const OnboardingContext = createContext<OnboardingContextArguments>({
  updateErrorMessage: () => null,
  updateFormFilledState: () => null,
  updateData: () => null,
  setIsDefaultTimezone: () => null,
  isDefaultTimezone: false,
  timezone: "",
  phoneNumber: "",
  userImage: "",
  timeFormat: "",
  organizationName: "",
  theme: "",
  organizationLogo: "",
  passwordConfirmation: "",
  organizationAddress: "",
  lastName: "",
  language: "",
  firstName: "",
  dateFormat: "",
  passwordNew: "",
  email: "",
  countryCode: "",
  countryISO: "",
});

export const Onboarding: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isPhoneVerificationShown, setIsPhoneVerificationShown] = useState<boolean>(false);
  const [isPhoneVerificationWasOpened, setIsPhoneVerificationWasOpened] = useState<boolean>(false);
  const [isDefaultTimezone, setIsDefaultTimezone] = useState(false);

  const [acceptInvitation, { loading: isAcceptInvitationLoading, error: acceptInvitationError }] =
    useMutation<AcceptInvitationResult>(ACCEPT_INVITATION_MUTATION);

  const [acceptInvitation2FA, { loading: isAcceptInvitation2FALoading, error: acceptInvitation2FAError }] =
    useMutation<AcceptInvitation2FaEmailResult>(ACCEPT_INVITATION_2FA_EMAIL_MUTATION);

  const [sendInvitationVerificationCode, { loading: isSendingVerificationCode, error: verificationCodeError }] =
    useMutation<SendInvitationVerificationCodeResult>(SEND_INVITATION_VERIFICATION_CODE_MUTATION);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const { userData, steps, token } = useSelector((state: RootState) => state.userOnboarding);
  const isLoading = isAcceptInvitationLoading || isAcceptInvitation2FALoading || isSendingVerificationCode;

  const {
    passwordNew: password,
    organizationName,
    organizationAddress,
    organizationLogo,
    firstName,
    lastName,
    phoneNumber: phone,
    userImage: picture,
    language: lang,
    timezone,
    theme,
    dateFormat,
    timeFormat,
  } = userData;

  const acceptInvitationArguments = {
    token,
    password,
    organizationName,
    organizationAddress,
    organizationLogo,
    firstName,
    lastName,
    phone,
    picture,
    lang,
    theme,
    timezone,
    dateFormat,
    timeFormat,
  };

  const locationStepIndex = steps.findIndex(({ routePath }) => routePath === location.pathname);

  const currentStepIndex = locationStepIndex === -1 ? 0 : locationStepIndex;

  const { translationKey, isConfirmButtonActive, hasRequiredFields, isLastForm } = steps[currentStepIndex];

  const stepLabels = steps.map((step) => t(translation.onboarding.steps[step.translationKey].label));
  const isPreviousStepAvailable = currentStepIndex !== 0;

  useEffect(() => {
    const isCurrentRouteAvailable = steps.every(({ isDataValid, hasRequiredFields }, index) => {
      if (index >= currentStepIndex) {
        return true;
      }

      return !hasRequiredFields || isDataValid;
    });

    if (isCurrentRouteAvailable) {
      return;
    }

    const nearestAvailableRouteIndex = steps.findIndex(({ hasRequiredFields, isDataValid }) => !hasRequiredFields || !isDataValid);

    navigate(steps[nearestAvailableRouteIndex].routePath, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasRequiredFields) {
      return;
    }

    dispatch(
      updateValidationState({
        currentStepIndex,
        isDataValid: !errorMessage && isConfirmButtonActive,
      }),
    );
  }, [currentStepIndex, errorMessage, isConfirmButtonActive, hasRequiredFields, dispatch]);

  const showErrorMessage = (): void => {
    dispatch(
      displayToast({
        text: errorMessage,
        type: ToastType.ERROR,
      }),
    );
  };

  const handleOnboardingComplete = (): void => {
    navigate(RoutePath.LOGIN, { replace: true });
  };

  const sendVerificationCode = (): Promise<FetchResult<SendInvitationVerificationCodeResult>> =>
    sendInvitationVerificationCode({
      variables: {
        token,
        phone: userData.phoneNumber,
      },
    });

  const handleNextClick = async (): Promise<void> => {
    if (isConfirmButtonActive && errorMessage) {
      return showErrorMessage();
    }

    if (currentStepIndex + 1 !== steps.length) {
      navigate(steps[currentStepIndex + 1].routePath);
      return;
    }

    if (userData.twoFactorVerificationMethod === OtpContactType.PHONE) {
      if (!isPhoneVerificationWasOpened) {
        await sendVerificationCode();
        setIsPhoneVerificationWasOpened(true);
      }

      setIsPhoneVerificationShown(true);
      return;
    }

    if (userData.twoFactorVerificationMethod === OtpContactType.EMAIL) {
      await acceptInvitation2FA({
        variables: {
          ...acceptInvitationArguments,
          otpContact: OtpContactType.EMAIL,
        },
      });
      handleOnboardingComplete();
      return;
    }

    await acceptInvitation({
      variables: acceptInvitationArguments,
    });
    handleOnboardingComplete();
  };

  const handleVerificationSuccess = async (): Promise<void> => {
    setIsPhoneVerificationShown(false);
    await acceptInvitation({
      variables: acceptInvitationArguments,
    });
    handleOnboardingComplete();
  };

  const handlePreviousClick = (): void => {
    const previousStepIndex = currentStepIndex - 1;

    if (previousStepIndex < 0) {
      return;
    }

    navigate(steps[previousStepIndex].routePath);
  };

  const handleStepClick = (stepIndex: number): void => {
    const isNextStep = stepIndex > currentStepIndex;

    if (isNextStep && isConfirmButtonActive && errorMessage) {
      return showErrorMessage();
    }

    navigate(steps[stepIndex].routePath);
  };

  const isStepDisabled = (stepIndex: number): boolean => {
    if (stepIndex === currentStepIndex) {
      return true;
    }

    if (stepIndex < currentStepIndex) {
      return false;
    }

    if (stepIndex === currentStepIndex + 1 && isConfirmButtonActive) {
      return false;
    }

    return !steps.every(({ isConfirmButtonActive }, index) => {
      if (index <= currentStepIndex) {
        return true;
      }

      if (stepIndex < index) {
        return true;
      }

      return isConfirmButtonActive;
    });
  };

  const updateFormFilledState = useCallback(
    (isFormFilled: boolean) => {
      dispatch(
        updateConfirmButtonState({
          isConfirmButtonActive: isFormFilled,
          currentStepIndex,
        }),
      );
    },
    [currentStepIndex, dispatch],
  );

  const updateErrorMessage = (message = ""): void => {
    setErrorMessage(message);
  };

  const updateData = (data: UpdateUserDataArguments): void => {
    dispatch(updateUserData(data));
  };

  const handleHidePhoneVerification = (): void => {
    setIsPhoneVerificationShown(false);
  };

  if (acceptInvitationError || acceptInvitation2FAError || verificationCodeError) {
    dispatch(displayToastError(t(translation.errors.unHandled)));
  }

  return (
    <div className={styles.container}>
      <StepBar steps={stepLabels} currentStep={currentStepIndex} isStepDisabled={isStepDisabled} onStepClick={handleStepClick} />

      <div className={styles.wrapper}>
        <OnboardingHeader
          title={t(translation.onboarding.steps[translationKey].title)}
          subTitle={t(translation.onboarding.steps[translationKey].subTitle)}
        />

        <div className={styles.content}>
          <OnboardingContext.Provider
            value={{
              updateErrorMessage,
              updateFormFilledState,
              setIsDefaultTimezone,
              isDefaultTimezone,
              updateData,
              ...userData,
            }}
          >
            <Outlet />
          </OnboardingContext.Provider>
        </div>

        <OnboardingFooter
          isRequired={hasRequiredFields}
          isLastForm={isLastForm}
          isNextAvailable={isConfirmButtonActive}
          isPreviousAvailable={isPreviousStepAvailable}
          loading={isLoading && !isPhoneVerificationShown}
          onNextClick={handleNextClick}
          onPreviousClick={handlePreviousClick}
        />
      </div>

      <PhoneVerification
        token={token}
        isShown={isPhoneVerificationShown}
        resendCode={sendVerificationCode}
        hide={handleHidePhoneVerification}
        onVerificationSuccess={handleVerificationSuccess}
      />
    </div>
  );
};
